import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';

function NewProjectType({ show, setShow, onChecklistCreated }) {
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [checklist, setChecklist] = useState('');
  const [errors, setErrors] = useState({});
  const [ProjectTypes, setProjectTypes] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setSelectedProjectId(null); // Reset selected project type
    setChecklist('');
    setErrors({});
  };

  const getProjectTypes = () => {
    API.get('/admin/project-types')
      .then((response) => {
        setProjectTypes(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching project types:', error);
      });
  };

  useEffect(() => {
    if (show) {
      getProjectTypes();
    }
  }, [show]);

  const handleSave = () => {
    const validationErrors = {};

    // Validate project type selection
    if (!selectedProjectId) {
      validationErrors.projectType = 'Please select a project type.';
    }

    // Validate checklist input
    if (!checklist.trim()) {
      validationErrors.checklist = 'Checklist is required.';
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      API.post(`/admin/projects/${selectedProjectId}/checklists`, {
        projectId: selectedProjectId,
        checklist,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success('Created Successfully');
            onChecklistCreated(selectedProjectId, response.data.checklistAdded.id);
            handleClose();
          } else {
            toast.error('Details not saved.');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error saving data:', error);
          setErrors({ axiosError: 'Error saving data' });
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Modal centered show={show} onHide={handleClose} className="add-checklist-member">
        {loading && (
          <div className="rotate-line-loader">
            <Bars
              visible={true}
              height="150"
              width="150"
              color="orange"
              strokeColor="orange"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
            />
          </div>
        )}

        <Modal.Header closeButton>
          <h4 className="modal-title">
            Add new checklist
          </h4>
        </Modal.Header>

        <Modal.Body>
          <label htmlFor="projectType">Select the project type</label>
          <div className="form-group selectDropdown">
            <select
              name="Opportunity"
              id="types"
              onChange={(e) => setSelectedProjectId(e.target.value)}
              className={`form-control ${errors.projectType ? 'is-invalid' : ''}`}
            >
              <option value="">Select Project Type</option>
              {ProjectTypes &&
                ProjectTypes.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
            {errors.projectType && <div className="invalid-feedback">{errors.projectType}</div>}
          </div>

          <div className="field">
            <label htmlFor="checklist">Enter the checklist</label>
            <input
              id="checklist"
              placeholder="Enter the checklist"
              className={`form-control ${errors.checklist ? 'is-invalid' : ''}`}
              value={checklist}
              onChange={(e) => setChecklist(e.target.value)}
            />
            {errors.checklist && <div className="invalid-feedback">{errors.checklist}</div>}
          </div>
        </Modal.Body>

        <div className="sr-btn-combo-box form-btn-rows">
          <button type="button" className="global-icon-btn orange-btn" onClick={handleSave}>
            Save
          </button>
          <button type="button" className="global-icon-btn" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default NewProjectType;
