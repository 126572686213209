import React from 'react';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import 'react-datepicker/dist/react-datepicker.css';

function DatePickerComponent({ fromDate, toDate, onFromDateChange, onToDateChange }) {
  return (
    <div className="select-date-pro">
      <div className="select-heading">
        <h4>Select Period:</h4>
      </div>
      <div className="dateBlock">
        <div className="datePickerWrapper">
          <label htmlFor="fromDate">From Date</label>
          <DatePicker
            id="fromDate"
            selected={fromDate}
            onChange={onFromDateChange}
            selectsStart
            startDate={fromDate}
            endDate={toDate}
            maxDate={new Date()}
            dateFormat="MM/dd/yyyy"
            placeholderText="Select From Date"
            className="datePickerInput"
            customInput={
              <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                {(inputProps) => <input {...inputProps} />}
              </InputMask>
            }
          />
        </div>
        <div className="datePickerWrapper">
          <label htmlFor="toDate">To Date</label>
          <DatePicker
            id="toDate"
            selected={toDate}
            onChange={onToDateChange}
            selectsEnd
            startDate={fromDate}
            endDate={toDate}
            maxDate={new Date()}
            dateFormat="MM/dd/yyyy"
            placeholderText="Select To Date"
            className="datePickerInput"
            customInput={
              <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                {(inputProps) => <input {...inputProps} />}
              </InputMask>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default DatePickerComponent;
