// FilePreviewModal.js
import React from 'react';
import Modal from 'react-modal';

const FilePreviewPdf = ({ isModalOpen, closeModal, modalContent, modalType }) => {
    console.log('isModalOpen',isModalOpen);
    console.log('modalContent',modalContent);
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="File Preview"
      className="modal modalFile"
      overlayClassName="overlay"
      ariaHideApp={false}
    >
      <button onClick={closeModal} className="closeButton"><i class="fa fa-times" ></i></button>
      <div className="modalContent">
        {/* Check for file type and render accordingly */}
        {modalType === 'jpg' || modalType === 'jpeg' || modalType === 'png' || modalType === 'svg' ? (
          <img src={modalContent} alt="file" style={{ width: '100%' }} />
        ) : modalType === 'pdf' ? (
          <iframe src={modalContent} style={{ width: '100%', height: '600px' }} />
        ) : modalType === 'mp4' ? (
          <video src={modalContent} width="750" height="500" controls />
        ) : modalType === 'csv' ? (
          <img src="/assets/images/csv-svgrepo-com.svg" alt="file" />
        ) : modalType === 'doc' || modalType === 'docx' || modalType === 'xlsx' ? (
          <img src="/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg" alt="file" />
        ) : modalType === 'zip' ? (
          <img src="/assets/images/compressed-file-zip-svgrepo-com.svg" alt="file" />
        ) : modalType === 'html' ? (
          <iframe src={modalContent} style={{ width: '100%', height: '600px' }} />
        ) : modalType === 'xml' ? (
          <img src="/assets/images/xml-file-format-variant-svgrepo-com.svg" alt="file" />
        ) : (
          <p>Unsupported file format</p>
        )}
      </div>
    </Modal>
  );
};

export default FilePreviewPdf;
