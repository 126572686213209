import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { pageTitle } from "../../features/actions/pageTitleActions";
import API from "../../AxiosConfig";
import { Link } from "react-router-dom";
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import SearchBar from "./SearchBar";
import DatePickerComponent from "./DatePickerComponent";
import LazyImageWithSpinner from "../lazyLoadImage/LazyImageWithSpinner";
import ProgressProjects from "./ProgressProjects";

function Progress() {
  const [data, setData] = useState([]); // Set initial state as an empty array
  const [dataOrder, setDataOrder] = useState([]); // Set initial state as an empty array
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [userName, setUserName] = useState(""); 
  const userData = useSelector(state => state.auth.user);
  const userId = userData?.user?.id
  
  
  const title = "Progress";
  const dispatch = useDispatch();

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

const fetchDiscussionstoday = useCallback(async () => {
  try {
    const response = await API.get(`/admin/discussions/today?userId=${userId}`);
        const sortedData = Object.entries(response.data.groupedProgressContent || {})
      .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA));
    setData(sortedData); // Set sorted data
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}, [userId]); // Depend on userId

useEffect(() => {
  if (userId) {
    fetchDiscussionstoday();
  }
}, [userId]); // Re-run when userId changes

  const handleFromDateChange = async (date) => {
    setFromDate(date);
    fetchDiscussions(date, toDate, userName);
  };

  const handleToDateChange = async (date) => {
    setToDate(date);
    fetchDiscussions(fromDate, date, userName);
  };

  const formatDateDisplay = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const isToday = (date) => date.toDateString() === new Date().toDateString();
  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.toDateString() === yesterday.toDateString();
  };

  useEffect(() => {
    fetchDiscussionstoday();
    fetchDiscussionstoday();
    dispatch(
      setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Progress", path: "/progress", active: true },
      ])
    );
    dispatch(pageTitle(title));
  }, [fetchDiscussionstoday,userId, dispatch, title]);

  const fetchDiscussions = async (from, to, user) => {
    if (from && to) {
      try {
        const response = await API.get("/admin/discussion", {
          params: {
            from: from.toISOString(),
            to: to.toISOString(),
            firstName: user,
          },
        });
        setData(null);
        const sortedData = Object.entries(
          response.data.groupedDiscussions || {}
        ).sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));
        // setDataOrder(sortedData); // Ensure we set sorted data
        setData(sortedData); // Ensure we set sorted data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      fetchDiscussions(fromDate, toDate, userName);
    }
  };

  const limitWords = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <div className="main-dashboard-admin">
      <div className="checklist-header-wrap">
        <div className="progress-radio-out date-progress-section">
          <SearchBar
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <DatePickerComponent
            fromDate={fromDate}
            toDate={toDate}
            onFromDateChange={handleFromDateChange}
            onToDateChange={handleToDateChange}
          />
        </div>
      </div>
      <section className="stream infinite_page">
        <div className="main-progress-outer">
          <div className="progress-inner">
            {data && Object.keys(data).length > 0
              ? data.map(([date, projects], index) => {
                  // Filter the groups based on userName
                  const filteredProjects = Object.values(projects)
                    .map((group) => {
                      const filteredGroup = userName
                        ? group.filter((item) =>
                            `${item.user.firstName} ${item.user.lastName}`
                              .toLowerCase()
                              .includes(userName.toLowerCase())
                          )
                        : group;

                      return filteredGroup.length > 0
                        ? filteredGroup.sort(
                            (a, b) =>
                              new Date(b.datetime) - new Date(a.datetime)
                          )
                        : null;
                    })
                    .filter((group) => group !== null); // Remove empty groups

                  if (filteredProjects.length === 0) return null;

                  // Ensure we return the ProgressProjects component
                  return (
                    <ProgressProjects
                      key={index} // Add a unique key for React reconciliation
                      filteredProjects={filteredProjects}
                      date={date}
                      index={index}
                    />
                  );
                })
              : "" // Render nothing if data is empty
            }

          
          </div>
        </div>
      </section>
    </div>
  );
}

export default Progress;
