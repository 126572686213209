import React from "react";
import { Link } from "react-router-dom";
import LazyImageWithSpinner from "../lazyLoadImage/LazyImageWithSpinner";



// Component to render grouped projects and discussions
function ProgressProjects({ filteredProjects, date, index }) {
  console.log('filteredProjects1', filteredProjects);
  console.log('date1', date);

  const formatDateDisplay = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Utility function to limit words in a string
  const limitWords = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  // Utility function to strip HTML tags
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };



  return (
    <div key={index}>
      <div className='day'>
        <a href="javascript:void(0)">
          <span>
            <time className="subhead">{formatDateDisplay(date)}</time>
          </span>
        </a>
      </div>
      {filteredProjects
        .sort((a, b) => new Date(b[0]?.datetime) - new Date(a[0]?.datetime))
        .map((group, index) => (
          <div key={`${date}_${index}`} className={`event_container ${index % 2 === 0 ? 'even' : 'odd'}`}>
            <div className={`project ${index % 2 === 0 ? 'project-left-data' : 'project-right-data'}`}>
              <Link to={group[0]?.linkPath}>{group[0]?.project?.opportunityName}</Link>
            </div>
            {
              group
                .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
                .map((item, idx) => (
                  <article key={idx} className={`event ${index % 2 === 0 ? 'event-left' : 'event-right'}`}>
                    <div className='avatar'>
                      <Link to={item.linkPath}>
                        <LazyImageWithSpinner src={item.user.profilePictureUrl} alt={item.user.firstName} />
                      </Link>
                    </div>
                    <div className='action'>
                      <span className="creator">{item?.user?.firstName} {item?.user?.lastName} </span>
                      <span className="in_timeline">
                        {/* commented on {item.project?.company?.name} - <Link to={item.linkPath} className="decorated">{item.title}</Link> */}
                        {/* commented on {item.project?.company?.name}  */}
                        <Link to={item.linkPath} className="decorated">commented on {item.project?.company?.name}</Link>
                      </span>
                      <div className='time'>
                        <span>
                          Sent at {new Date(item.datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                        </span>
                      </div>
                      <div className='in_timeline comment'>
                        <p>{limitWords(stripHtmlTags(item?.progress_content || ''), 20)}</p>
                      </div>
                    </div>
                  </article>
                ))}
          </div>
        ))}
    </div>
  );
}

export default ProgressProjects;
