import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
function FilePreview({ showPreviewModal, setShowPreviewModal, previewFile }) {
  console.log('showPreviewModal',showPreviewModal);

  const handleClose = () => {
    setShowPreviewModal(false);
  };

  const handleDownload = () => {
    if (previewFile?.file) {
      const link = document.createElement('a');
      link.href = previewFile.file; // File URL
      link.download = previewFile.storageKey || 'downloaded_file'; // Default file name
      link.target = '_blank'; // Open in a new tab
      document.body.appendChild(link); // Append to the DOM
      link.click(); // Trigger the download
      document.body.removeChild(link); // Remove after download
    } else {
      toast.error("File not available for download!");
    }
  };


const getFileDisplay = (file) => {
  if (file?.originalname.match(/\.(jpg|jpeg|png)$/i)) {
    return (
          <img
              src={file.file || URL.createObjectURL(file)}              
              alt="File Preview"
              className="file-icon"
            /> 
        
    );
  } else if (file?.originalname?.match(/\.(zip)$/i)) {
    return (
     
          <img
            src="/assets/images/zip.png"
            alt="ZIP File"
            style={{ width: '120px', height: '120px' }}
          />
       
    );
  } else if (file?.originalname?.match(/\.(pdf)$/i)) {
    return (
     
          <img
            src="/assets/images/pdf.png"
            alt="PDF File"
            style={{ width: '120px', height: '120px' }}
          />
    );
  } else if (file?.originalname?.match(/\.(doc|docx)$/i)) {
    return (
          <img
            src="/assets/images/doc.png"
            alt="Word Document"
            style={{ width: '120px', height: '120px' }}
          />
       
    );
  } else {
    return (
          <img
            src="/assets/images/document.png"
            alt="Unknown Document"
            style={{ width: '120px', height: '120px' }}
          />
    );
  }
};

  return (
    <Modal
      centered
      show={showPreviewModal}
      onHide={handleClose}
      className="file-details-modal download-modal-in"
    >
      <Modal.Header closeButton>
        {/* <h4 className="modal-title">
          {previewFile?.storageKey || "File Details"}
        </h4> */}
      </Modal.Header>
      <Modal.Body>
        <div className="file-preview-container">
          <div className="file-preview text-center">
            {/* <img
              src={previewFile?.file || "https://via.placeholder.com/120"}
              alt="File Preview"
              className="file-icon"
            /> */}
             {getFileDisplay(previewFile)}
          </div>
          <div className='sr-head-btn'>
            <div className='sr-head-only'>
               <h4 className="modal-title">
                  {previewFile?.originalname || "File Details"}
                </h4>
            </div>
            <div className="file-action-buttons">
              <button
                type="button"
                className="btn btn-success w-100 mb-2"
                onClick={handleDownload}
              >
                Download this file
              </button>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Modal.Body>
    </Modal>
  );
}

export default FilePreview;
