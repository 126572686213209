import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { Link, NavLink } from 'react-router-dom';
import API from '../../AxiosConfig';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';
import { format } from 'date-fns';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 


function TimeTrackingListing() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [Projects, setProjects] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState("all");
  const [selectedClient, setSelectedClient] = useState("all");
  const [projectDetails, setProjectDetails] = useState(null);
  const [allProjectDetails, setAllProjectDetails] = useState(null);
  const [allProjectDetailsorder, setAllProjectDetailsorder] = useState(null);
  
  console.log("allProjectDetails", allProjectDetails);
  const [totalHours, setTotalHours] = useState(0);
  const [billableHours, setBillableHours] = useState(0);
  const [nonBillableHours, setNonBillableHours] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState('');
  const [editingRecord, setEditingRecord] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [editedValues, setEditedValues] = useState({
    description: '',
    billableHours: ''
  });
  const [confirmDelete, setConfirmDelete] = useState(null);
  const confirmDeleteHour = (hourId) => {
      handleDeleteHour(hourId);
      setConfirmDelete(null); 
  };
  
  const cancelDelete = () => {
    setConfirmDelete(null); 
};

  const handleDeleteHour = async (hourId) => {
    try {
        const response = await API.delete(`/timeTracking/timeTracking/${hourId}`);
        if (response.data.success) {
            toast.success('Hour entry deleted successfully');
          getAllProjectsDetails();
        } else {
            toast.error('Error while deleting hour entry');
        }
    } catch (error) {
        console.error('Error deleting hour entry:', error);
        toast.error('Error while deleting hour entry');
    }
};

  const handleEditClick = (record) => {
    setEditingRecord(record.id);
    setEditedValues({
      billableHours: record.billableHours,
      nonBillableHours: record.nonBillableHours
    });
  };

  const handleSaveClick = () => {
    setEditingRecord(null); 
  };

  const handleFromDateChange = (date) => {
  setFromDate(date);
};

const handleToDateChange = (date) => {
  setToDate(date);
};

useEffect(() => {
  if (fromDate && toDate) {
    fetchData(fromDate, toDate, selectedProject);
  }
}, [fromDate, toDate, selectedProject]);

const handleUpdateHour = async () => {
  try {
      const response = await API.put(`/timeTracking/timeTracking/${editingRecord}`, editedValues);
      if (response.data.success) {
          toast.success('Hour entry updated successfully');
          getAllProjectsDetails();
          setEditingRecord(null);
      } else {
          toast.error('Error while updating hour entry');
      }
  } catch (error) {
      console.error('Error updating hour entry:', error);
      toast.error('Error while updating hour entry');
  }
};

const handleUpdateHourOne = async () => {
  try {
      const response = await API.put(`/timeTracking/timeTracking/${editingRecord}`, editedValues);
      if (response.data.success) {
          toast.success('Hour entry updated successfully');
          getProjects();
          setEditingRecord(null);
      } else {
          toast.error('Error while updating hour entry');
      }
  } catch (error) {
      console.error('Error updating hour entry:', error);
      toast.error('Error while updating hour entry');
  }
};

const handleDurationChange = (event) => {
  const duration = event.target.value;
  const today = new Date();
  let startDate;
  let endDate;

  switch (duration) {
    case 'Week':
      startDate = new Date();
      startDate.setDate(startDate.getDate() - startDate.getDay());
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      break;
    case 'Month':
      startDate = new Date();
      startDate.setDate(1);
      endDate = new Date(startDate);
      endDate.setMonth(startDate.getMonth() + 1);
      endDate.setDate(0);
      break;
    case '6 Months':
      startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 6);
      endDate = new Date();
      break;
    case 'Year':
      startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      endDate = new Date();
      break;
    default:
      startDate = null;
      endDate = null;
  }

  setFromDate(startDate);
  setToDate(endDate);
  setSelectedDuration(duration);
};

  const getProjects = async () => {
    try {
      const response = await API.get('admin/project');
      if (response?.data) {
        setProjects(response.data);
      } else {
        toast.error('Error while getting the data', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Error while getting the data', {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (startDate, endDate, projectId) => {
    try {
      setAllProjectDetails(null);
      const response = await API.get(`/timeTracking/timeTracking/${projectId}`, {
        params: {
          startDate: format(fromDate, 'yyyy-MM-dd'),
          endDate: format(toDate, 'yyyy-MM-dd'),
          projectId: projectId || 'all'
        }
      });

      if (response?.data) {
        if (projectId == 'all' || projectId == undefined ) {
          setAllProjectDetailsorder(response.data);
          console.log("Fetched all projects data", response.data);
        } else {
          setProjectDetails(response.data.projects[0]);
          console.log("Fetched single project data", response.data); 
        }
        getTotalTime();
      } else {
        toast.error('Error while getting the data', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Error while getting the data', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  
  
  const handleDateChange = (setter) => (date) => {
    setter(date);
  };
  
  const getProjectDetails = async (projectId) => {
    try {
      const response = await API.get(`/timeTracking/timeTracking/${projectId}`);
      if (response?.data) {
        console.log(response.data);
        setProjectDetails(response.data.projects[0]);      
        getSingleProjectData();
      } else {
        toast.error('Error while getting project details', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Error while getting project details', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  
  const handleProjectChange = (e) => {
    const projectId = e.target.value;
    setSelectedProject(projectId);
    setSelectedClient("all");
    if (projectId === "all") {
      getAllProjectsDetails();
      setProjectDetails(null);
      getTotalTime();
    } else {
      setTotalHours(null);
      setBillableHours(null);
      setNonBillableHours(null);
      getProjectDetails(projectId);
      setAllProjectDetails(null);
      getSingleProjectData();
    }
  };

  const [clientProject, setclientProject] = useState(null);
  const handleClientChange = async (e) => {
    const companyId = e.target.value;
    setSelectedClient(companyId);
    await getAllProjectsDetails();
    // Filter projects based on the selected company ID
    if (companyId === "all") {
      setclientProject(null);
    } else {
      setSelectedProject("all");
      setSelectedClient(companyId);

        const filtered = allProjectDetails.projects.filter(
            project => project.company && project.company.id == companyId
        );
        console.log("filtered", filtered);
        setclientProject({projects: filtered});
        getSingleClientData();  
    }
};


  const dispatch = useDispatch();
  const title = 'Time Tracker';
  
  const getTotalTime = async () => {
    if (allProjectDetails?.projects?.length > 0) {
      let total = 0;
      let billable = 0;
      let nonBillable = 0;
  
      allProjectDetails.projects.forEach(project => {
        project.trackRecords?.forEach(record => {
          const billableHour = parseFloat(record.billableHours) || 0;
          const nonBillableHour = parseFloat(record.nonBillableHours) || 0;
  
          billable += billableHour;
          nonBillable += nonBillableHour;
          total += billableHour + nonBillableHour;
        });
      });
  
      console.log('total', total, 'billable', billable, 'nonBillable', nonBillable);
      setTotalHours(total);
      setBillableHours(billable);
      setNonBillableHours(nonBillable);
    }
  };

  const getAllProjectsDetails = async () => {
    try {
      const response = await API.get(`/timeTracking/getAllProjectsDetails`);
      if (response && response.data) {
        setAllProjectDetails(response.data);
        console.log("all", response.data);
      } else {
        toast.error('Error while getting all projects details', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Error while getting all projects details', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  
  const getSingleProjectData = async () => {
    if (projectDetails?.trackRecords) {
      let total = 0;
      let billable = 0;
      let nonBillable = 0;
  
      projectDetails.trackRecords.forEach(record => {
        const billableHour = parseFloat(record.billableHours) || 0;
        const nonBillableHour = parseFloat(record.nonBillableHours) || 0;
  
        total += billableHour + nonBillableHour;
        billable += billableHour;
        nonBillable += nonBillableHour;
      });
  
      setTotalHours(total);
      setBillableHours(billable);
      setNonBillableHours(nonBillable);
    }
  };

  const getSingleClientData = async () => {
  
      if (clientProject?.projects?.length > 0) {
        let total = 0;
        let billable = 0;
        let nonBillable = 0;
    
        clientProject.projects.forEach(project => {
          project.trackRecords?.forEach(record => {
            const billableHour = parseFloat(record.billableHours) || 0;
            const nonBillableHour = parseFloat(record.nonBillableHours) || 0;
    
            billable += billableHour;
            nonBillable += nonBillableHour;
            total += billableHour + nonBillableHour;
          });
        });
    
        console.log('total111', total, 'billab1111le', billable, 'n11onBillable', nonBillable);
        setTotalHours(total);
        setBillableHours(billable);
        setNonBillableHours(nonBillable);
      }
  };
  
  
  useEffect(() => {
    dispatch(pageTitle(title));
    getProjects();
    getAllProjectsDetails();
    dispatch(setBreadcrumbs([
      { label: "Home", path: "/" },
      { label: "Time Tracking", path: "/timeTracking", active: true }
    ]));
    
  }, [dispatch, title]);
  
  useEffect(() => {
    if (allProjectDetails) {
      getTotalTime();
    }
  }, [allProjectDetails]);
  
  useEffect(() => {
    if (projectDetails) {
      getSingleProjectData();
    }
  }, [projectDetails]);
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (error) {
    return <div>Error loading data</div>;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const singleDayHour = (billableHours, nonBillableHours) => {  
    return (parseFloat(billableHours) || 0) + (parseFloat(nonBillableHours) || 0);
  };

  return (
    <div className="main-dashboard-admin timeTracking">
            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
      <div className="checklist-header-wrap">
        <div className="selectValues sr-cstm-daterange">
        <div className="fields week">
        <label htmlFor="duration">Select Duration</label>
        <div className="selectDropdown">
          <select
            name="duration"
            id="duration"
            value={selectedDuration}
            onChange={handleDurationChange}
          >
            <option value="Year">All</option>
            <option value="Week">Week</option>
            <option value="Month">Month</option>
            <option value="6 Months">6 Months</option>
            <option value="Year">Year</option>
          </select>
          <div className="dropIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
              <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
            </svg>
          </div>
        </div>
      </div>
      <div className="select-date-pro">
        <div className='select-heading'><h4>Select Period</h4></div>
        <div className="dateBlock">
          <div className="datePickerWrapper">
            {/* <label htmlFor="fromDate">From Date</label> */}
            <DatePicker
              id="fromDate"
              selected={fromDate}
              onChange={handleFromDateChange}
              selectsStart
              startDate={fromDate}
              endDate={toDate}
              maxDate={new Date()}
              dateFormat="MM/dd/yyyy"
              placeholderText="Select From Date"
              className="datePickerInput"
              customInput={
              <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                  {(inputProps) => <input {...inputProps} />}
                </InputMask>
              }
            />
          </div>
          <div className="datePickerWrapper">
            {/* <label htmlFor="toDate">To Date</label> */}
            <DatePicker
              id="toDate"
              selected={toDate}
              onChange={handleToDateChange}
              selectsEnd
              startDate={fromDate}
              endDate={toDate}
              minDate={fromDate}
              maxDate={new Date()}
              dateFormat="MM/dd/yyyy"
              placeholderText="Select To Date"
              className="datePickerInput"
              customInput={
                <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                  {(inputProps) => <input {...inputProps} />}
                </InputMask>
              }
            />
          </div>
        </div>
      </div>
          {/* <div className="button-wrapper">
            <button className="primaryBtn">Get Details</button>
          </div> */}
          <div className="button-wrapper">
            <Link to={'/addManualTime'}  >
              <button className="primaryBtn">Add Manual Time</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="totalHoursBlock">
        <div className="projectDetailsB">
          <div className="selectValues">
            <div className="fields">
              <label for="">Select Project</label>
              <div className="selectDropdown">
                <select name="Opportunity" id="types" onChange={handleProjectChange}  value={selectedProject}>
                  <option value="all">All</option>
                  {Projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.opportunityName}
                    </option>
                  ))}
                </select>
                <div className="dropIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
                  </svg>
                </div>
              </div>
            </div>
         
          </div>
          <div className="selectValues">
          <div className="fields">
              <label for="">Select Client</label>
              <div className="selectDropdown">
              <select name="Client" id="types" onChange={handleClientChange} value={selectedClient} >
                <option value="all">All</option>
                {Array.from(new Set(Projects.map(project => project.company.name))).map((companyName) => {
                    const project = Projects.find(proj => proj.company.name === companyName);
                    return (
                        <option key={project.company.id} value={project.company.id}>
                            {companyName}
                        </option>
                    );
                })}
            </select>

                <div className="dropIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
                  </svg>
                </div>
              </div>
            </div>
            </div>

          {projectDetails ? (
            <div className="projectInfo">
              <div className="info">
                <label>Client Name:</label>
                <p>{projectDetails?.company?.name}</p>
              </div>
              <div className="info">
                <label>Project Name:</label>
                <p>{projectDetails?.opportunityName}</p>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="monthlyhours">
          <div className="headingTotalHr">
            <p> <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"/>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
            <g id="SVGRepo_iconCarrier"> <rect width="24" height="24" fill="white"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.8321 14.5547C15.5257 15.0142 14.9048 15.1384 14.4453 14.8321L11.8451 13.0986C11.3171 12.7466 11 12.1541 11 11.5196L11 11.5L11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7L13 11.4648L15.5547 13.1679C16.0142 13.4743 16.1384 14.0952 15.8321 14.5547Z" fill="#000000"/> </g>
            </svg>
              <span>Total Hours</span> </p>
          </div>
          <div className="projectDates">
            {/* {allProjectDetails && allProjectDetails.projects.length > 0 ? ( */}
            {!clientProject && allProjectDetails && allProjectDetails.projects && allProjectDetails.projects.length > 0 ? (

             <ul>
            {allProjectDetails.projects
  .filter(project => project.trackRecords && project.trackRecords.length > 0)
  .flatMap(project => 
    project.trackRecords.map(trackRecord => ({
      ...trackRecord, 
      project: project  // Add project data to the track record
    }))
  )
  .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
  .map((record, index) => (
    <li key={index} className="projectID">
      <div className="datesProj">
        <p>{formatDate(record.date)}:<span>{record.project.opportunityName} asd asd <br></br>({record.project.company.name}) Here</span></p>
      </div>
      <div className="bookingFrom">
        <p>{record.description}</p>
        <div className="bfromName">
          <svg xmlns="http://www.w3.org/2000/svg" width="13.068" height="13.068" viewBox="0 0 13.068 13.068">
            <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M6.534,7.351A3.675,3.675,0,1,0,2.859,3.675,3.676,3.676,0,0,0,6.534,7.351ZM9.8,8.168H8.395a4.443,4.443,0,0,1-3.721,0H3.267A3.267,3.267,0,0,0,0,11.435v.408a1.225,1.225,0,0,0,1.225,1.225H11.843a1.225,1.225,0,0,0,1.225-1.225v-.408A3.267,3.267,0,0,0,9.8,8.168Z"></path>
          </svg>
          <p>{record.user.firstName}</p>
        </div>
      </div>
      <div className="hours">
        {editingRecord === record.id ? (
          <div className='EditingHours'>
            <div className='editingHoursinnderDiv'>
                <div className='sr-cstm-hours-div'>
                    <label>Billable Hours</label>
                    <input
                        type="number"
                        min={0}
                        value={editedValues.billableHours}
                        onChange={(e) => setEditedValues({ ...editedValues, billableHours: e.target.value })}
                    />
                </div>
                <div className='sr-cstm-hours-div'>
                    <label>Non Billable Hours</label>
                    <input
                        type="number"
                        min={0}
                        value={editedValues.nonBillableHours}
                        onChange={(e) => setEditedValues({ ...editedValues, nonBillableHours: e.target.value })}
                    />
                </div>
            </div>
          </div>
        ) : (
          <p className='sr-cstm-hours-cls'> 
            <span>B: {Number(record.billableHours) % 1 === 0 ? Number(record.billableHours).toFixed(0) : Number(record.billableHours)} hrs</span>
            <span>NB: {Number(record.nonBillableHours) % 1 === 0 ? Number(record.nonBillableHours).toFixed(0) : Number(record.nonBillableHours)} hrs</span>
            <span>T: {Number(singleDayHour(record.billableHours, record.nonBillableHours)) % 1 === 0 ? Number(singleDayHour(record.billableHours, record.nonBillableHours)).toFixed(0) : Number(singleDayHour(record.billableHours, record.nonBillableHours))} hrs</span>
        </p>
        )}
      </div>
      {editingRecord === record.id ? (
        <>
          <div className='sr-cstm-fa-icom-wrap'> 
            <button className='cstm-fav-icon' onClick={handleUpdateHour}>
              <i className="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>
            </button>
            <button className='cstm-fav-icon' onClick={() => setEditingRecord(false)}>
              <i className="fa fa-times" aria-hidden="true" style={{ color: 'red' }}></i>
            </button>
          </div>
        </>
      ) : (
        confirmDelete === record.id ? (
          <div className='sr-cstm-fa-icom-wrap'> 
            <button className='cstm-fav-icon' onClick={() => confirmDeleteHour(record.id)}>
              <i className="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>
            </button>
            <button className='cstm-fav-icon' onClick={cancelDelete}>
              <i className="fa fa-times" aria-hidden="true" style={{ color: 'red' }}></i>
            </button>
          </div>
        ) : (
          <div className='sr-cstm-fa-icom-wrap'> 
            <button className='cstm-fav-icon' onClick={() => handleEditClick(record)}>
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>
            <button className='cstm-fav-icon' onClick={() => setConfirmDelete(record.id)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        )
      )}
    </li>
  ))
}

           </ul>
            ) : (
              ''
            )}
            



            {!clientProject && allProjectDetailsorder && allProjectDetailsorder.projects && allProjectDetailsorder.projects.length > 0 ? (

                        <ul>
                        {allProjectDetailsorder.projects
                        .filter(project => project.trackRecords && project.trackRecords.length > 0)
                        .flatMap(project => 
                        project.trackRecords.map(trackRecord => ({
                        ...trackRecord, 
                        project: project  // Add project data to the track record
                        }))
                        )
                        .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date in descending order
                        .map((record, index) => (
                        <li key={index} className="projectID">
                        <div className="datesProj">
                        <p>{formatDate(record.date)}:<span>{record.project.opportunityName} <br></br>({record.project.company.name}) sad asd </span></p>
                        </div>
                        <div className="bookingFrom">
                        <p>{record.description}</p>
                        <div className="bfromName">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.068" height="13.068" viewBox="0 0 13.068 13.068">
                        <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M6.534,7.351A3.675,3.675,0,1,0,2.859,3.675,3.676,3.676,0,0,0,6.534,7.351ZM9.8,8.168H8.395a4.443,4.443,0,0,1-3.721,0H3.267A3.267,3.267,0,0,0,0,11.435v.408a1.225,1.225,0,0,0,1.225,1.225H11.843a1.225,1.225,0,0,0,1.225-1.225v-.408A3.267,3.267,0,0,0,9.8,8.168Z"></path>
                        </svg>
                        <p>{record.user.firstName}</p>
                        </div>
                        </div>
                        <div className="hours">
                        {editingRecord === record.id ? (
                        <div className='EditingHours'>
                        <div className='editingHoursinnderDiv'>
                          <div className='sr-cstm-hours-div'>
                              <label>Billable Hours</label>
                              <input
                                  type="number"
                                  min={0}
                                  value={editedValues.billableHours}
                                  onChange={(e) => setEditedValues({ ...editedValues, billableHours: e.target.value })}
                              />
                          </div>
                          <div className='sr-cstm-hours-div'>
                              <label>Non Billable Hours</label>
                              <input
                                  type="number"
                                  min={0}
                                  value={editedValues.nonBillableHours}
                                  onChange={(e) => setEditedValues({ ...editedValues, nonBillableHours: e.target.value })}
                              />
                          </div>
                        </div>
                        </div>
                        ) : (
                        <p className='sr-cstm-hours-cls'> 
                        <span>B: {Number(record.billableHours) % 1 === 0 ? Number(record.billableHours).toFixed(0) : Number(record.billableHours)} hrs</span>
                        <span>NB: {Number(record.nonBillableHours) % 1 === 0 ? Number(record.nonBillableHours).toFixed(0) : Number(record.nonBillableHours)} hrs</span>
                        <span>T: {Number(singleDayHour(record.billableHours, record.nonBillableHours)) % 1 === 0 ? Number(singleDayHour(record.billableHours, record.nonBillableHours)).toFixed(0) : Number(singleDayHour(record.billableHours, record.nonBillableHours))} hrs</span>
                        </p>
                        )}
                        </div>
                        {editingRecord === record.id ? (
                        <>
                        <div className='sr-cstm-fa-icom-wrap'> 
                        <button className='cstm-fav-icon' onClick={handleUpdateHour}>
                        <i className="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>
                        </button>
                        <button className='cstm-fav-icon' onClick={() => setEditingRecord(false)}>
                        <i className="fa fa-times" aria-hidden="true" style={{ color: 'red' }}></i>
                        </button>
                        </div>
                        </>
                        ) : (
                        confirmDelete === record.id ? (
                        <div className='sr-cstm-fa-icom-wrap'> 
                        <button className='cstm-fav-icon' onClick={() => confirmDeleteHour(record.id)}>
                        <i className="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>
                        </button>
                        <button className='cstm-fav-icon' onClick={cancelDelete}>
                        <i className="fa fa-times" aria-hidden="true" style={{ color: 'red' }}></i>
                        </button>
                        </div>
                        ) : (
                        <div className='sr-cstm-fa-icom-wrap'> 
                        <button className='cstm-fav-icon' onClick={() => handleEditClick(record)}>
                        <i className="fa fa-edit" aria-hidden="true"></i>
                        </button>
                        <button className='cstm-fav-icon' onClick={() => setConfirmDelete(record.id)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                        </div>
                        )
                        )}
                        </li>
                        ))
                        }

                        </ul>
                        ) : (
                        ''
                        )}
















            {clientProject && clientProject.projects.length > 0 ? (
             <ul>
             {clientProject.projects
               .filter(project => project.trackRecords && project.trackRecords.length > 0)
               .flatMap(project =>
                project.trackRecords
                  .slice() // Create a shallow copy to avoid mutating the original data
                  .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
                  .map((record, index) => (
                   <li key={index} className="projectID">
                     <div className="datesProj">
                     <p>{formatDate(record.date)}:<span>{record.project.opportunityName} <br></br>({record.project.company.name})</span></p>
                     </div>
                     <div className="bookingFrom">
                         <p>{record.description}</p>
                       <div className="bfromName">
                         <svg xmlns="http://www.w3.org/2000/svg" width="13.068" height="13.068" viewBox="0 0 13.068 13.068">
                           <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M6.534,7.351A3.675,3.675,0,1,0,2.859,3.675,3.676,3.676,0,0,0,6.534,7.351ZM9.8,8.168H8.395a4.443,4.443,0,0,1-3.721,0H3.267A3.267,3.267,0,0,0,0,11.435v.408a1.225,1.225,0,0,0,1.225,1.225H11.843a1.225,1.225,0,0,0,1.225-1.225v-.408A3.267,3.267,0,0,0,9.8,8.168Z"></path>
                         </svg>
                         <p>{record.user.firstName}</p>
                       </div>
                     </div>
                     <div className="hours">
                       {editingRecord === record.id ? (
                        <div className='EditingHours'>
                        {/* <h6>Editing hours for {selectedHour.description}</h6> */}
                        <div className='editingHoursinnderDiv'>
                            <div className='sr-cstm-hours-div'>
                                <label>Billable Hours</label>
                                <input
                                    type="number"
                                    min={0}
                                    value={editedValues.billableHours}
                                    onChange={(e) => setEditedValues({ ...editedValues, billableHours: e.target.value })}
                                />
                            </div>
                            <div className='sr-cstm-hours-div'>
                                <label>Non Billable Hours</label>
                                <input
                                    type="number"
                                    min={0}
                                    value={editedValues.nonBillableHours}
                                    onChange={(e) => setEditedValues({ ...editedValues, nonBillableHours: e.target.value })}
                                />
                            </div>
                            <div className='btns-tasks-det'>
                                {/* <button className="btn btn-secondary sr-cstm-btn"onClick={() => setIsEditingTime(false)}>Cancel</button>
                                <button className="btn btn-primary sr-cstm-btn save-btn" onClick={handleUpdateHour}>Save</button> */}
                            </div>
                        </div>
                    </div>
                       ) : (
                        <p className='sr-cstm-hours-cls'> 
                          <span>B: {Number(record.billableHours) % 1 === 0 ? Number(record.billableHours).toFixed(0) : Number(record.billableHours)} hrs</span>
                          <span>NB: {Number(record.nonBillableHours) % 1 === 0 ? Number(record.nonBillableHours).toFixed(0) : Number(record.nonBillableHours)} hrs</span>
                          <span>T: {Number(singleDayHour(record.billableHours, record.nonBillableHours)) % 1 === 0 ? Number(singleDayHour(record.billableHours, record.nonBillableHours)).toFixed(0) : Number(singleDayHour(record.billableHours, record.nonBillableHours))} hrs</span>
                      </p>


                       )}
                     </div>
                     {editingRecord === record.id ? (
                          <>
                           <div className='sr-cstm-fa-icom-wrap'> 
                              <button className='cstm-fav-icon' onClick={handleUpdateHour}>
                                <i className="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>
                              </button>
                              <button className='cstm-fav-icon' onClick={() => setEditingRecord(false)}>
                                <i className="fa fa-times" aria-hidden="true" style={{ color: 'red' }}></i>
                              </button>
                            </div>
                            {/* <button className="btn btn-secondary sr-cstm-btn" onClick={() => setEditingRecord(false)}>Cancel</button>
                            <button className="btn btn-primary sr-cstm-btn save-btn" onClick={handleUpdateHour}>Save</button>  */}
                          </>
                        ) : (
                          confirmDelete === record.id ? (
                            <div className='sr-cstm-fa-icom-wrap'> 
                              <button className='cstm-fav-icon' onClick={() => confirmDeleteHour(record.id)}>
                                <i className="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>
                              </button>
                              <button className='cstm-fav-icon' onClick={cancelDelete}>
                                <i className="fa fa-times" aria-hidden="true" style={{ color: 'red' }}></i>
                              </button>
                            </div>
                          ) : (
                            <div className='sr-cstm-fa-icom-wrap'> 
                              <button className='cstm-fav-icon' onClick={() => handleEditClick(record)}>
                                <i className="fa fa-edit" aria-hidden="true"></i>
                              </button>
                              <button className='cstm-fav-icon' onClick={() => setConfirmDelete(record.id)}>
                                <i className="fa fa-trash" aria-hidden="true"></i>
                              </button>
                            </div>
                          )
                        )}
                   </li>
                 ))
               )}
           </ul>
            ) : (
              ''
            )}


            {projectDetails?.trackRecords?.map((record, index) => (
              <div className="projectID" key={record.id}>
                <div className="datesProj">
                  <p>{formatDate(record.date)}:<span>Project Name: <br></br>({projectDetails?.opportunityName})</span></p>
                </div>
                <div className="bookingFrom">
                  <p>{record.description}</p>
                  <div className="bfromName">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.068" height="13.068" viewBox="0 0 13.068 13.068">
                      <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M6.534,7.351A3.675,3.675,0,1,0,2.859,3.675,3.676,3.676,0,0,0,6.534,7.351ZM9.8,8.168H8.395a4.443,4.443,0,0,1-3.721,0H3.267A3.267,3.267,0,0,0,0,11.435v.408a1.225,1.225,0,0,0,1.225,1.225H11.843a1.225,1.225,0,0,0,1.225-1.225v-.408A3.267,3.267,0,0,0,9.8,8.168Z"></path>
                    </svg>
                    <p>{record.user.firstName}</p>
                  </div>
                </div>
                <div className="hours">
                {editingRecord === record.id ? (
                        <div className='EditingHours'>
                        {/* <h6>Editing hours for {selectedHour.description}</h6> */}
                        <div className='editingHoursinnderDiv'>
                            <div className='sr-cstm-hours-div'>
                                <label>Billable Hours</label>
                                <input
                                    type="number"
                                    min={0}
                                    value={editedValues.billableHours}
                                    onChange={(e) => setEditedValues({ ...editedValues, billableHours: e.target.value })}
                                />
                            </div>
                            <div className='sr-cstm-hours-div'>
                                <label>Non Billable Hours</label>
                                <input
                                    type="number"
                                    min={0}
                                    value={editedValues.nonBillableHours}
                                    onChange={(e) => setEditedValues({ ...editedValues, nonBillableHours: e.target.value })}
                                />
                            </div>
                            <div className='btns-tasks-det'>
                                {/* <button className="btn btn-secondary sr-cstm-btn"onClick={() => setIsEditingTime(false)}>Cancel</button>
                                <button className="btn btn-primary sr-cstm-btn save-btn" onClick={handleUpdateHour}>Save</button> */}
                            </div>
                        </div>
                    </div>
                       ) : (
                        <p className='sr-cstm-hours-cls'> 
                        <span>B: {Number(record.billableHours) % 1 === 0 ? Number(record.billableHours).toFixed(0) : Number(record.billableHours)} hrs</span>
                        <span>NB: {Number(record.nonBillableHours) % 1 === 0 ? Number(record.nonBillableHours).toFixed(0) : Number(record.nonBillableHours)} hrs</span>
                        <span>T: {Number(singleDayHour(record.billableHours, record.nonBillableHours)) % 1 === 0 ? Number(singleDayHour(record.billableHours, record.nonBillableHours)).toFixed(0) : Number(singleDayHour(record.billableHours, record.nonBillableHours))} hrs</span>
                    </p>
                    
                       )}                </div>
                        {editingRecord === record.id ? (
                      <>
                       <button className="btn btn-secondary sr-cstm-btn"onClick={() => setEditingRecord(false)}>Cancel</button>
                        <button className="btn btn-primary sr-cstm-btn save-btn" onClick={handleUpdateHourOne}>Save</button> 
                      </>
                     ) : (
                      <>
                      <div className='sr-cstm-fa-icom-wrap'> 
                       <button className='cstm-fav-icon' onClick={() => handleEditClick(record)}>
                         <i className="fa fa-edit" aria-hidden="true"></i>
                       </button>
                       <button className='cstm-fav-icon' onClick={() => setConfirmDelete(record.id)}>
                       <i className="fa fa-trash" aria-hidden="true"></i>
                     </button>
                     </div>
                     </>
                     )}
                     
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="totalhoursDetails">
      
        <div className="hoursD">
          <label>
            <svg xmlns="http://www.w3.org/2000/svg" width="15.703" height="16.531" viewBox="0 0 15.703 16.531">
              <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(-6.5 -6.086)">
                <path id="Path_1" data-name="Path 1" d="M7.5,18H21.2" transform="translate(0 -3.648)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                <path id="Path_2" data-name="Path 2" d="M18,7.5l6.852,6.852L18,21.2" transform="translate(-3.648)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
              </g>
            </svg>Billable</label>
          <p>{billableHours}</p>
        </div>
        <div className="hoursD">
          <label>
            <svg xmlns="http://www.w3.org/2000/svg" width="15.703" height="16.531" viewBox="0 0 15.703 16.531">
              <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(-6.5 -6.086)">
                <path id="Path_1" data-name="Path 1" d="M7.5,18H21.2" transform="translate(0 -3.648)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                <path id="Path_2" data-name="Path 2" d="M18,7.5l6.852,6.852L18,21.2" transform="translate(-3.648)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
              </g>
            </svg>Non Billable</label>
          <p>{nonBillableHours}</p>
        </div>
        <div className="hoursD">
          <label>
            <svg xmlns="http://www.w3.org/2000/svg" width="15.703" height="16.531" viewBox="0 0 15.703 16.531">
              <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(-6.5 -6.086)">
                <path id="Path_1" data-name="Path 1" d="M7.5,18H21.2" transform="translate(0 -3.648)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
                <path id="Path_2" data-name="Path 2" d="M18,7.5l6.852,6.852L18,21.2" transform="translate(-3.648)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
              </g>
            </svg>Total Time</label>
          <p>{totalHours}</p>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light'  />


    </div>

  )
}
 
export default TimeTrackingListing