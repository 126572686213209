import React from 'react';

function SearchBar({ value, onChange, onKeyDown }) {
  return (
    <div className="searchBar">
      <div className="field">
        <input
          type="search"
          placeholder="Search for User"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <button type="submit">
          <i className="fa fa-search"></i>
        </button>
      </div>
    </div>
  );
}

export default SearchBar;
