import React , {useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { Link } from 'react-router-dom';
import API from '../../AxiosConfig';
import { useParams, useNavigate } from 'react-router-dom';

function EmailLogsDetail ()
{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const title = 'Logs Detail';
    const { id } = useParams();
    const [logData,setLogData] = useState({});

    const logDetail = () => {
        API.get('/admin/logsDetail/'+id+'')
        .then((response)=>{
            setLogData(response.data.data);
            console.log(response.data.data);
        }).catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        dispatch(pageTitle(title));
        logDetail();
    },[]);

    return (
        <div className="main-dashboard-admin">
            <div className="teamInfoTable email-table">
            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
            <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button>
                <table className='mail-logs' border="0" width="100%">
                    <thead>
                        <tr>
                            <th>Receiver Name</th>
                            <th>Receiver Email</th>
                           <th>Receiver Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{logData.receiverName}</td>
                            <td>{logData.email}</td>
                            <td>{logData?.message?.replace(/<\/?[^>]+(>|$)/g, "")}</td>

                            {/* <td className="message">{logData.message.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 20)}</td> */}

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        // <div className="main-dashboard-admin">
        //      <div className="sr-cstm-padding">
        //      <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>

        //         <h2>{title}</h2>
        //         {logData ? (
        //             <table className="log-table">
        //                 <thead>
        //                     <tr>
        //                         <th>Receiver Name</th>
        //                         <th>Receiver Email</th>
        //                         <th>Receiver Message</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     <tr>
        //                         <td>{logData.receiverName}</td>
        //                         <td>{logData.email}</td>
        //                         <td>{logData.message}</td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         ) : (
        //             <p>Loading...</p>
        //         )}
        //     </div>
        // </div>
    )
}
export default EmailLogsDetail
