import React, { useEffect, useState } from 'react'
import { Link, Outlet, useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../features/actions/authActions';
import Header from '../../layouts/Header';
import API from '../../AxiosConfig';


function PageNotFound({ isLoggedIn, handleLogin  }) {
  
  
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState('');
  const reduxAuth = useSelector((state) => state?.auth?.isAuthenticated);
  const userReduxData = useSelector(state=>state.auth.user);
  useEffect(() => {
    if (isLoggedIn && token) {
      API.post('/verify-token',{"token":token})
        .then((response) => {
          if (response.status === 200) {
            dispatch(loginSuccess(response.data));
            handleLogin(token);
          } else if (response.status === 202 || (response.data.error && response.data.error.name === 'TokenExpiredError')) {
            localStorage.removeItem('token');
            handleLogin(token);
          } else {
            console.log(response.data.message);
            handleLogin(token);
          }
        })
    } 
    
  }, [isLoggedIn])
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/", { replace: true });
    } else {
      setCurrentUser({ username: userReduxData?.user?.name, role: userReduxData?.user?.role?.role });
    }
  }, [isLoggedIn,userReduxData]);
  return (
    <>
     <div className="main-dashboard-admin not-found-page">
        <div className='not-inner'>
        <h2>Hmm, that isn’t right</h2>
         <p className='p-text'>You may have typed the URL incorrectly.</p>
        <p>Check to make sure you’ve got the spelling, capitalization, etc. exactly right.</p>
        </div>
        <div className='back-btn-not'>
          <Link to={`/`}>back to home</Link></div>
    </div>
    </>
  )
}

export default PageNotFound