import React, { useState } from 'react';
import Modal from 'react-modal';
import FilePreviewPdf from './FilePreviewPdf';
import FilePreview from './Modals/filePreviewModal';


function DocumentPopup ({ comment ,handleShowPreviewModal  }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalContentExt, setModalContentExt] = useState('');
  const [modalType, setModalType] = useState(''); // Used to track the type of content (image, video, etc.)

  const handleShowPreviewPDFModal = (file, originalname) => {
    console.log('originalname', originalname);
    console.log('file', file);
  
    const fileExtension = originalname.split('.').pop();
    setModalContentExt(fileExtension);
    console.log('fileExtension', fileExtension);
  
    if (fileExtension !== 'pdf') {
      // If the file is not a PDF, delegate to another function
      setModalContent({file, originalname});
      setIsModalOpen(true);
      // handleShowPreviewModal(file, originalname);
    } else {
      // If the file is a PDF, set the modal-specific data
      setModalType(fileExtension); // Set file type for conditionally rendering
      setModalContent(file); // Set file content to be displayed in the modal
      setIsModalOpen(true); // Open the modal
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    setModalType('');
  };


  return (
    <div
    className="fileouter"
    >
      {/* Render the list of files */}
      {comment.storages.length > 0 &&
        comment.storages.map((storage, index) => (
          <div
            key={index}
            className="fileContainer"
            onClick={() => handleShowPreviewPDFModal(storage.file, storage.originalname)}
          >
            {
              (storage.storageKey.split('.').pop() === 'jpg' ||
              storage.storageKey.split('.').pop() === 'JPG' ||
                storage.storageKey.split('.').pop() === 'jpeg' ||
                storage.storageKey.split('.').pop() === 'png' ||
                storage.storageKey.split('.').pop() === 'svg') ? (
                <img src={storage.file} alt="file" />
              ) : storage.storageKey.split('.').pop() === 'csv'||
              storage.storageKey.split('.').pop() === 'xlsx'  ? (
                <img src="/assets/images/csv-svgrepo-com.svg" style={{"width": "100px"}} alt="file" />
              ) : storage.storageKey.split('.').pop() === 'doc' ||
                storage.storageKey.split('.').pop() === 'docx' ? (
                <img
                  src="/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg"
                  alt="file"
                />
              ) : storage.storageKey.split('.').pop() === 'html' ? (
                <img src="/assets/images/html-5-svgrepo-com.svg" alt="file" />
              ) : storage.storageKey.split('.').pop() === 'xml' 
              ? (
                <img
                  src="/assets/images/xml-file-format-variant-svgrepo-com.svg"
                  alt="file"
                />
              ) : storage.storageKey.split('.').pop() === 'zip' ? (
                <img
                  src="/assets/images/compressed-file-zip-svgrepo-com.svg"
                  alt="file"
                />
              ) : storage.storageKey.split('.').pop() === 'pdf' ? (
                <img
                  src="/assets/images/pdf.png"
                  alt="PDF file"
                />
              ) : storage.storageKey.split('.').pop() === 'xlsx' ? (
                <img
                  src="/assets/images/pdf.png"
                  alt="xlsx file"
                />
              ) : storage.storageKey.split('.').pop() === 'mp4' ? (
                <video
                  src={storage.file}
                  width="750"
                  height="500"
                  controls
                ></video>
              ) : (
                <img src="/assets/images/file-unknown.svg" alt="file" />
              )
            }
           <div class="text"> {storage.originalname}</div>
          </div>
        ))}

      {/* Modal for previewing the file */}

      {
  modalContentExt && modalContentExt === 'pdf' ? (
    <FilePreviewPdf
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      modalContent={modalContent}
      modalType={modalType}
    />
  ) : (
    <FilePreview
      showPreviewModal={isModalOpen}
      setShowPreviewModal={setIsModalOpen}
      previewFile={modalContent}
    />
  )
}
      


      
    </div>
  );
};

export default DocumentPopup;
