import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import Card from 'react-bootstrap/Card';
import { MultiSelect } from "react-multi-select-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import { ToastContainer, toast } from 'react-toastify';
import socketIOClient from 'socket.io-client';
import socket from '../../socket';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import DiscusionReactions from './Modals/discusionReactions';
import { Bars } from 'react-loader-spinner';
import LazyImageWithSpinner from '../lazyLoadImage/LazyImageWithSpinner';
import FilePreview from './Modals/filePreviewModal';
import SetDueDates from './Modals/setDueDateModal';
import DocumentPopup from './DocumentPopup';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 

function DiscussionDetail() {
    const dispatch = useDispatch();
    const title = 'Discussion Detail';
    const [previewFile, setPreviewFile] = useState(null);
    const { id } = useParams();
    const { sectionID } = useParams();
    const navigate = useNavigate();
    const [discussion, setDiscussionDetail] = useState({});
    const [teamMembers, setTeamMembers] = useState({});
    const [awaitingTeamMembers, setawaitingTeamMembers] = useState(null);
    const [selected, setSelected] = useState([]);
    const [usersOutFromDisscusion, setUsersOutFromDiscussion] = useState([]);
    const keys = { id: '', comment: '', commentableId: id };
    const [formData, setFormData] = useState(keys);
    const [filesData, setFilesData] = useState({ files: '' });
    const [filesDataDisplay, setFilesDataDisplay] = useState({ files: '' });
    const [errors, setErrors] = useState(keys);
    const [editable, setEditable] = useState(null);
    const [selectedfile, SetSelectedFile] = useState([]);
    const [showAddTeamMember, setShowAddTeamMember] = useState(null);
    let options = [];
    const [response, setResponse] = useState("");
    const [deleteableId, setDeleteableId] = useState('');
    const [show, setShow] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const ActiveuserId = userData?.user?.id;
    const [showModal, setShowModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showCommentReply, setshowCommentReply] = useState(false);
    const [visibleApproveLists, setVisibleApproveLists] = useState({});
    const [visibleApproveDLists, setVisibleApproveDLists] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState('');
    const [SelectedCommentIdReply, setSelectedCommentIdReply] = useState('');
    const [reaction, setReaction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [description, setDescription] = useState(discussion?.description || "");
    const [errorsFile, setErrorsFile] = useState('');
    const [displayFile, setDisplayFile] = useState('');
    const [status, setStatus] = useState("");
    const [mentionSearch, setMentionSearch] = useState('');
    const [showTooltip, setShowTooltip] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [users, setUsers] = useState([]);  
    const [commentId, setCommentId] = useState([]);  
    const editorRef = useRef(null);
    const tooltipRef = useRef(null);
    const [showUnModal, setShowUnModal] = useState(false);
    const handleShowUnModal = (commentId) => {
        setShowUnModal(true);
        setCommentId(commentId)
    };

    


    const bottomRef = useRef(null); // Reference to scroll to the bottom

  const handleCommentClick = (commentId) => {
    setSelectedCommentIdReply(commentId);
    // Scroll to the bottom of the page or specific element
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

    const fetchAllUsers = async () => {
        try {
            const response = await API.get('/admin/users');
            if (Array.isArray(response.data)) {
                const usersList = response.data.map(user => ({
                    id: user.id,
                    name: `${user.firstName} ${user.lastName}` 
                }));
                setUsers(usersList); 
            } else {
                console.error("Unexpected response format:", response.data);
                setUsers([]); 
            }
        } catch (e) {
            console.error("Error fetching users:", e);
        }
    };

    const handlemarkModal = async (commentId) => {
        try {
            const response = await API.post(`/admin/update-completion`, {
                commentId: commentId,
                completed: 1, 
                completedUser: ActiveuserId
            });
    
            if (response.status === 200) {
                toast.success("Comment marked as completed");
                getDiscussionsDetail();

            } else {
                console.error('Error updating comment:', response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setFormData({ ...formData, comment: data });
        const textContent = data.replace(/<\/?[^>]+(>|$)/g, "").trim();
        const lastChar = textContent.charAt(textContent.length - 1);
        if (lastChar === '@') {
            setShowTooltip(true);
        } else if (lastChar === ' ' || lastChar === '<' || lastChar === '>') {
            setShowTooltip(false);
        }
        const mentionText = textContent.split('@')[1];
        if (mentionText) {
            setMentionSearch(mentionText);
            if (Array.isArray(users)) {
                const filtered = users.filter(user =>
                    user.name.toLowerCase().includes(mentionText.toLowerCase())
                );
                setFilteredUsers(filtered);
            }
        }

    };

    const handleEditorBlur = (event, editor) => {
        let data = editor.getData();
        const urlRegex = /(?<!<a\s[^>]*?>)(https:\/\/[^\s<]+)/g;
        const modifiedContent = data.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
        if (modifiedContent !== data) {
            editor.setData(modifiedContent); 
        }
    };

    const insertMention = (user) => {
        const editor = editorRef.current.editor;
        const data = editor.getData();
        const newData = data.replace(`@${mentionSearch}`, `@${user.name}`);
        editor.setData(newData);
        setShowTooltip(false);
    };

    useEffect(() => {
        fetchAllUsers();
         
        const timer = setTimeout(() => {
            if (showTooltip && tooltipRef.current) {
                tooltipRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }, 1500);  

    
        return () => clearTimeout(timer); // Cleanup timer if needed
    }, [showTooltip]);
    

    useEffect(() => {
        setTimeout(() => {
            let section = document.getElementById(sectionID);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1000);
    }, [sectionID]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const refreshState = () => {
        getDiscussionsDetail();
    };
    useEffect(() => {
        refreshState(); 
    }, []); 
    

    useEffect(() => {
        setDescription(discussion?.description)
    }, [discussion]);

    const handleAcceptClick = async () => {
        setIsEditing(false);
        try {
            await API.put(`/admin/upateDiscussion/${discussion.id}`, {
                description: description, 
            });
            getDiscussionsDetail();
        } catch (error) {
            console.error('Error updating description:', error);
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setDescription(discussion?.description || ""); 
    };


    const handleReactionClick = async (type, commentId) => {
        setReaction(type);

        const reactionData = {
            discussion_id: id,
            user: userData.user.id,
            comment_id: commentId,
            like: type === 'like' ? true : null,
            dislike: type === 'dislike' ? true : null,
            love: type === 'love' ? true : null,
            approved: type === 'approved' ? true : null,
            not_approved: type === 'not_approved' ? true : null,
        };

        try {
            const response = await API.post(`/admin/reactions`, reactionData);
            getDiscussionsDetail();
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
        }
    };


    if (awaitingTeamMembers && awaitingTeamMembers.length > 0) {
        awaitingTeamMembers.map((team) => {
            if (team.id != id) {
                options.push({ label: team['firstName'] + ' ' + team['lastName'], value: team['id'], 'discussionId': id })  //second one is dusscussion id 
            }
        });
    }

    const toggleApproveLists = (commentId) => {
        setVisibleApproveLists(prevState => ({
            ...prevState,
            [commentId]: !prevState[commentId] // Toggle     the specific comment's approve-lists visibility
        }));
    };


    

    const handleDiscussionToggle = () => {
        setVisibleApproveDLists(prevState => !prevState);
    };

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    //on page load
    const getDiscussionsDetail = () => {
        setLoading(true);
        API.get(`/admin/discussion-detail/${id}`)
            .then((response) => {
                console.log('response', response.data);
                setLoading(false);
                setDiscussionDetail(response.data.detail);
                setStatus(response.data.detail.status);
                setTeamMembers(response.data.team);
                setawaitingTeamMembers(response.data.awaitingTeam);
                let users = response.data.users.map(a => a.userId);
                setUsersOutFromDiscussion(users);
                dispatch(setBreadcrumbs([
                    { label: "Home", path: "/" },
                    { label: "Clients", path: "/clients" },
                    { label: "Project", path: "/clients" },
                    { label: response.data?.team?.opportunityName, path: `/clients/client-detail/${response.data?.team?.companyId}`, active: true }
                ]));
                document.title = response.data?.team?.opportunityName;
            }).catch((e) => {
                console.log(e);
            })
    }

    const handleShowModal = (commentId) => {
        setShowModal(true);
        setSelectedCommentId(commentId);

    };

    const handleShowPreviewModal = (file, originalname) => {
        setPreviewFile({ file, originalname });
        setShowPreviewModal(true);
      };
      

    // const handleCommentClick = (commentId) => {
    //     setSelectedCommentIdReply(commentId);
    // };

    const cleartheCommentID = () => {
        setSelectedCommentIdReply(null);
    };
    
    const selectedComment = discussion?.comments?.find((comment) => {
        return comment.id === SelectedCommentIdReply; 
    });
    

    const handleClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const ids = {};
        const allIds = { ...ids, 'discussionId': id, [name]: value, 'value': e.target.checked };
        API.post('/admin/add-remove-member-for-discussion', allIds)
            .then((response) => {
                getDiscussionsDetail()
            }).catch((e) => {
                console.log(e);
            });

    }

    const handleSelector = () => {
        API.post('/admin/addMemberInProjectFromDiscussion', selected)
            .then((response) => {
                setSelected([]);
                getDiscussionsDetail()
            }).catch((error) => {
                console.log(error);
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const file = e.target.files;
        if (file) {
            setFilesData({ ...filesData, [name]: file });
        }
        const fileList = Array.from(e.target.files);
        if (fileList.length > 0) {
            setFilesDataDisplay(fileList);
        }
    }

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const getFileDisplay = (file) => {
        if (file.type.startsWith('image/')) {
            return (
                <div key={file.name}>
                    <span><img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '50px', height: '50px' }} /></span>
                    <p>{file.name}</p>
                </div>
            );
        } else if (file.name.match(/\.(zip)$/i)) {
            return (
                <div key={file.name}>
                    <span><img src='/assets/images/zip.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
                    <p>{file.name}</p>
                </div>
            );
        } else if (file.name.match(/\.(pdf)$/i)) {
            return (
                <div key={file.name}>
                    <span><img src='/assets/images/pdf.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
                    <p>{file.name}</p>
                </div>
            );
        } else if (file.name.match(/\.(doc|docx)$/i)) {
            return (
                <div key={file.name}>
                    <span><img src='/assets/images/doc.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
                    <p>{file.name}</p>
                </div>
            );
        } else {
            return (
                <div key={file.name}>
                    <span><img src='/assets/images/document.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
                    <p>{file.name}</p>
                </div>
            );
        }
    };

    function Capitalize(str) {
        let strt = 'abc'
        return strt.charAt(0).toUpperCase() + strt.slice(1);
    }

    const fileChange = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                SetSelectedFile(
                    (preValue) => {
                        return [
                            ...preValue,
                            {
                                id: shortid.generate(),
                                filename: e.target.files[i].name,
                                filetype: e.target.files[i].type,
                                fileimage: reader.result,
                                datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                                filesize: filesizes(e.target.files[i].size)
                            }
                        ]
                    });
            }

            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }

    const DeleteSelectFile = (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(result);
        } else {
        }

    }

    function handleErrors() {
        let errorss = null;
        if (formData.comment.trim() == '') {
            errorss = true;
            setErrors({ ...errors, ['comment']: 'Please add comment first' })
        }
        else if (filesData.files != "") {
            const validExtensions = ['jpg', 'jpeg', 'png', 'csv', 'doc', 'html', 'xml', 'zip', 'pdf', 'mp4', 'docx', 'svg', 'xlsx'];
            for (let i = 0; i < filesData?.files?.length; i++) {
                const extension = filesData.files[i].name.split('.').pop().toLowerCase();
                if (!validExtensions.includes(extension)) {
                    errorss = true;
                    setErrorsFile('File extension is not allowed');
                }
                else {
                    setErrorsFile('');

                }
            }
        }

        return errorss;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        let form = new FormData();
        for (let i = 0; i < filesData?.files?.length; i++) {
            form.append('files', filesData.files[i]);
        }
        Object.keys(formData).forEach(key => {
            form.append(key, formData[key])
        });
        form.append('ActiveuserId', ActiveuserId);
        form.append('TeamMembers', JSON.stringify(teamMembers?.users));
        form.append('companyName', teamMembers.opportunityName);
        form.append('companyId', teamMembers.companyId);
        if (selectedComment && selectedComment.id) {
            form.append('reply_toComment', selectedComment.id);
          }
          
        const errors = handleErrors();
        if (!errors) {
            setLoading(true);
            if (formData.id !== '') {
                API.post('/admin/update-comment-into-project-discussion', form, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                })
                    .then((response) => {
                        toast.success('Comment updated successfully')
                        setFormData(keys);
                        setFilesData([]);
                        setDisplayFile('');
                        setErrors('');
                        setEditable(null);
                        getDiscussionsDetail();
                        setFilesDataDisplay('');
                        setSelectedCommentIdReply('');
                        setLoading(false);
                    }).catch((error) => {
                        setLoading(false);
                        toast.error('Something went wrong')
                    });
            }
            else {
                API.post('/admin/add-new-comment-into-project-discussion', form, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                })
                    .then((response) => {
                        setFormData(keys);
                        setLoading(false);
                        setFilesDataDisplay('');
                        setFilesData([]);
                        getDiscussionsDetail();
                        setSelectedCommentIdReply('');
                        setErrors('');
                        setEditable(null);
                    }).catch((error) => {
                        setLoading(false);
                        toast.error('Something went wrong')
                    });
            }
        }
        else {
            console.log(errors);

        }
        setEditable(null);
        setFormData({
            ...formData, id: ''
        });
    }

    //edit comment 
    const handleEdit = (e) => {
        setErrors(null);
        const commmentId = e.target.value;
        console.log('commmentId',commmentId);
        setFormData({
            ...formData, id: commmentId
        });
        const fileUrlString = e.target.getAttribute('fileUrl');
        API.get(`/admin/find-discussion-comment/${commmentId}`).
            then((response) => {
                const data = response.data.data;
                console.log(data);
                setFormData({
                    comment: data.comment,id: commmentId
                });
                setDisplayFile(data.fileURL);
                setEditable(response.data.data);
            }).catch((e) => {
                console.log(e);
            })
    }
    console.log(formData);
    const handleDiscussionEdit = (e) => {
        const discussionId = e.target.value;
        API.get(`/admin/find-discussion/${discussionId}`).
            then((response) => {


            }).catch((e) => {
                console.log(e);
            })
    }

    //comment delete
    const handleDelete = (e) => {
        setEditable(null);
        setFormData(keys);
        const { value } = e.target;
        setDeleteableId(value);
        setShow(true);
    }
    const handleConfirmDelete = () => {
        API.get(`/admin/delete-discussion-comment/${deleteableId}/${ActiveuserId}`).
            then((response) => {
                toast.success('Comment deleted successfully')
                getDiscussionsDetail();
                setShow(false);
            }).catch((e) => {
                console.log(e);
                toast.error('Something went wrong')
                setShow(false);
            });
    }
    const handleClose = () => {
        setShow(false);
    }
    const handleCancel = (e) => {
        setFormData(keys)
        setEditable(null)
    }
    const handleDiscussionDelete = (e) => {
        const id = e.target.value;
        const projectId = e.target.getAttribute('data-project-id');
        const token = localStorage.getItem('token');
        API.get(`/admin/delete-discussion/${id}`)
            .then((response) => {
                toast.success('Discussion deleted successfully')
                // getDiscussionsDetail();
                setTimeout(() => navigate(`/clients/client-detail/project-detail/discussions/${projectId}`), 1000);
            }).catch((e) => {
                toast.error('Something went wrong')
                console.log(e);
            })
    }

    const AddMembersPopup = () => setShowAddTeamMember(true);
    const closeAddMembersPopup = () => setShowAddTeamMember(false);

    useEffect(() => {
        if (selected.length > 0) {
            handleSelector();
            getDiscussionsDetail();
        }
        else {
            dispatch(pageTitle(title));
            getDiscussionsDetail();
        }
        getDiscussionsDetail();

        socket.on('server_message', (message) => {
            setDiscussionDetail(message);
        });
    }, [selected, status])


    const handleStatusChange = async (event) => {
        const newStatus = event.target.value;
        const oldStatus = status; // Assuming `status` holds the current status
        const currentDate = new Date().toLocaleDateString(); // Format date as needed

        setStatus(newStatus);

        try {
            // Update the status
            await API.put('/admin/updateStatus', { status: newStatus, id: id });

            // Create the comment
            const comment = `${userData?.user.firstName} ${userData?.user.lastName} changed the status from ${oldStatus} to ${newStatus} on ${currentDate}`;

            // Post the comment
            await API.post('/admin/add-new-comment-into-project-discussion', {comment: comment, commentableId: id, commentableType: 'discussions', ActiveuserId: ActiveuserId, TeamMembers: JSON.stringify(teamMembers?.users) });
            await getDiscussionsDetail();

        } catch (error) {
            console.error('Error updating status or posting comment:', error);
        }
    };

    


    return (
        <div className='main-dashboard-admin'>
            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
            <div className="container">

            </div>
            {loading &&
                <div className='rotate-line-loader'>
                    <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
                </div>}
            <div className='details-discussion-outer'>
                <div className='detail-grid left'>
                    <div class="dis-head">
                        <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(`/clients/client-detail/project-detail/discussions/${discussion.projectId}`)}> <i class="fas fa-sign-out-alt"></i></button>
                        <h2>  {discussion?.title} D - {discussion?.serialNo}</h2>
                    </div>
                    {/* <button className="sr-cstm-primary" >Reactions</button> */}
                    {Object.keys(discussion).length > 0 ?
                        <div className='details-boxes'>
                            <div className="project-sr">
                                <div className="project-peo-wrap">
                                    <div className='sr-cstm-div-img-date cstmdate-cls'>

                                        <div className="project-peo-img">
                                            <LazyImageWithSpinner src={discussion?.addedByUser?.profilePictureUrl} alt='client' />
                                            <h4>{discussion?.addedByUser?.firstName}</h4>
                                        </div>
                                        <div className="project-peo-cont">
                            <div className='sr-react-date'>
                                <div className='edits-calender'>
                                    <i className="fas fa-calendar-check"></i>
                                    <p>{new Date(discussion?.createdAt).toLocaleString()}</p>
                                </div>
                            </div>

                            {/* Conditionally render either text or input field */}
                           
                        </div>
                    </div>
                   
                                    {
                                        discussion?.storages.length > 0 ?
                                            discussion?.storages.map(storage =>
                                                <div className="filesContainer">
                                                    {
                                                        (storage.storageKey.split('.').pop() == 'jpg' || storage.storageKey.split('.').pop() == 'jpeg') || (storage.storageKey.split('.').pop() == 'png') || (storage.storageKey.split('.').pop() == 'svg') ?
                                                            <a href={storage.file}><img src={storage.file} alt="file" /></a>
                                                            :
                                                            (storage.storageKey.split('.').pop() == 'csv')
                                                                ?
                                                                <a href={storage.file}>
                                                                    <img src="/assets/images/csv-svgrepo-com.svg" alt="file" />
                                                                </a>
                                                                :
                                                                (storage.storageKey.split('.').pop() == 'doc')
                                                                    ?
                                                                    <a href={storage.file}>
                                                                        <img src="/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg" alt="file" />
                                                                    </a>
                                                                    :
                                                                    (storage.storageKey.split('.').pop() == 'html')
                                                                        ?
                                                                        <a href={storage.file}>
                                                                            <img src="/assets/images/html-5-svgrepo-com.svg" alt="file" />
                                                                        </a>
                                                                        :
                                                                        (storage.storageKey.split('.').pop() == 'xml')
                                                                            ?
                                                                            <a href={storage.file}>
                                                                                <img src="/assets/images/xml-file-format-variant-svgrepo-com.svg" alt="file" />
                                                                            </a>
                                                                            :
                                                                            (storage.storageKey.split('.').pop() == 'zip')
                                                                                ?
                                                                                <a href={storage.file}>
                                                                                    <img src="/assets/images/compressed-file-zip-svgrepo-com.svg" alt="file" />
                                                                                </a>
                                                                                :
                                                                                (storage.storageKey.split('.').pop() == 'pdf')
                                                                                    ?
                                                                                    <a href={storage.file}>
                                                                                        <iframe src={storage.file} style={{ width: '100%', height: '600px' }} />
                                                                                    </a>
                                                                                    :
                                                                                    (storage.storageKey.split('.').pop() == 'mp4')
                                                                                        ?
                                                                                        <a href={storage.file}>
                                                                                            {/* <img src="/assets/images/video-streaming-outline-svgrepo-com.svg" alt="file"/> */}
                                                                                            <video src={storage.file} width="750" height="500" controls></video>
                                                                                        </a>
                                                                                        :
                                                                                        <img src="/assets/images/file-unknown.svg" alt="file" />
                                                    }
                                                </div>
                                                
                                            )
                                            : null
                                    }
                                    <div className='react-post'>
                                        {/* <div className='react-inner-d'  onClick={() => handleDiscussionToggle()}>
                                            <p>React to this post</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.003" height="15.003" viewBox="0 0 15.003 15.003">
                                            <g id="Icon_feather-smile" data-name="Icon feather-smile" transform="translate(-2.25 -2.25)">
                                                <path id="Path_1" data-name="Path 1" d="M16.5,9.751A6.751,6.751,0,1,1,9.751,3,6.751,6.751,0,0,1,16.5,9.751Z" transform="translate(0 0)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="Path_2" data-name="Path 2" d="M12,21a3.645,3.645,0,0,0,2.7,1.35A3.645,3.645,0,0,0,17.4,21" transform="translate(-4.949 -9.898)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="Path_3" data-name="Path 3" d="M13.5,13.5h.007" transform="translate(-5.774 -5.774)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="Path_4" data-name="Path 4" d="M22.5,13.5h.007" transform="translate(-10.723 -5.774)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                            </g>
                                            </svg>
                                        </div> */}
                                        {visibleApproveDLists && (
                                        <div className='approve-lists sr-approve-lists'>
                                            <ul>
                                                <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
                                                    <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M10.782,7.539V4.945A1.945,1.945,0,0,0,8.836,3L6.242,8.836V15.97h7.315a1.3,1.3,0,0,0,1.3-1.1l.895-5.836a1.3,1.3,0,0,0-1.3-1.492ZM6.242,15.97H4.3a1.3,1.3,0,0,1-1.3-1.3V10.133a1.3,1.3,0,0,1,1.3-1.3H6.242" transform="translate(-2.25 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                    <span>Like</span></a></li>
                                                    <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
                                                    <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M7.982,11.43v2.594A1.945,1.945,0,0,0,9.927,15.97l2.594-5.836V3H5.207a1.3,1.3,0,0,0-1.3,1.1L3.015,9.939a1.3,1.3,0,0,0,1.3,1.492ZM12.521,3h1.945a1.3,1.3,0,0,1,1.3,1.3V8.836a1.3,1.3,0,0,1-1.3,1.3H12.521" transform="translate(-2.247 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                    <span>Dislike</span></a></li>
                                                    <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="15.788" height="14.574" viewBox="0 0 15.788 14.574">
                                                    <path id="Icon_ionic-md-heart-empty" data-name="Icon ionic-md-heart-empty" d="M14.821,4.5a4.67,4.67,0,0,0-3.552,1.666A4.67,4.67,0,0,0,7.717,4.5,4.292,4.292,0,0,0,3.375,8.868c0,3.017,2.683,5.439,6.748,9.173l1.146,1.032,1.146-1.032c4.065-3.734,6.748-6.156,6.748-9.173A4.292,4.292,0,0,0,14.821,4.5ZM11.755,17l-.159.148-.326.3-.326-.3L10.783,17a48.239,48.239,0,0,1-4.657-4.63,5.568,5.568,0,0,1-1.533-3.5,3.157,3.157,0,0,1,.9-2.251,3.089,3.089,0,0,1,2.224-.9,3.481,3.481,0,0,1,2.622,1.233l.93,1.1.93-1.1a3.468,3.468,0,0,1,2.622-1.233,3.1,3.1,0,0,1,2.228.9,3.163,3.163,0,0,1,.9,2.251,5.6,5.6,0,0,1-1.533,3.5A48.337,48.337,0,0,1,11.755,17Z" transform="translate(-3.375 -4.5)" fill="#fff"/>
                                                    </svg>
                                                    <span>Love</span></a></li>
                                                    <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="18.518" height="13.67" viewBox="0 0 18.518 13.67">
                                                    <path id="Icon_feather-check" data-name="Icon feather-check" d="M22.4,9,11.124,20.86,6,15.469" transform="translate(-4.94 -7.94)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                    <span>Approved</span></a></li>
                                                    <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143" viewBox="0 0 14.143 14.143">
                                                    <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(7.072 -14.85) rotate(45)">
                                                        <path id="Path_8" data-name="Path 8" d="M18,7.5v16" transform="translate(-2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                        <path id="Path_9" data-name="Path 9" d="M7.5,18h16" transform="translate(0 -2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                    </g>
                                                    </svg>
                                                    <span>Not Approved</span></a></li>
                                            </ul>
                                        </div>
                                         )}
                                    </div>
                                    {/* <div className='details-edits-cal'>
                                        <p dangerouslySetInnerHTML={{ __html: discussion?.description }} ></p>
                                    </div> */}
                                     {!isEditing ? (
                                  <div className='details-edits-cal'>
                                  <p dangerouslySetInnerHTML={{ __html: discussion?.description }} ></p>
                              </div>
                            ) : (
                                <input
                                    type="text"
                                    value={description ? stripHtmlTags(description) : ''}
                                    onChange={(e) => setDescription(e.target.value)} // Update state on change
                                />
                              
                            )}

                            <div className='button-group'>
                                {/* Render edit/delete buttons or accept/cancel buttons based on editing state */}
                                
                            </div>
                                    <div className='edit-delete'>
                                                {
                                                    // Render Edit and Delete buttons only if the comment author's ID matches ActiveuserId and it's within 15 minutes of posted time
                                                    discussion.addedByUser.id === ActiveuserId && new Date() - new Date(discussion.createdAt) <= 15 * 60 * 1000 && (
                                                        <>
                                                        {!isEditing ? (
                                                            <>
                                                             <button className='btn' name='discussion_id' value={discussion.id} onClick={handleEditClick}><i className="fas fa-pencil-alt" style={{ pointerEvents: 'none' }}></i></button>
                                                            &nbsp;<span>|</span>&nbsp;
                                                            
                                                            <button
                                                                className='btn'
                                                                name='discussion_id'
                                                                value={discussion.id}
                                                                data-project-id={discussion.projectId} 
                                                                onClick={handleDiscussionDelete}
                                                                >
                                                                    <i className="far fa-trash-alt" style={{ pointerEvents: 'none' }}></i>
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button className='cstm-fav-icon' onClick={handleAcceptClick}>
                                                                    <i className="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>
                                                                </button>
                                                                <button className='cstm-fav-icon' onClick={() => setIsEditing(false)}>
                                                                    <i className="fa fa-times" aria-hidden="true" style={{ color: 'red' }}></i>
                                                                </button>
                                                            </>
                                                        )}
                                                           
                                                            </>
                                                    )
                                                }
                                            </div>
                                </div>
                            </div>

                        </div>
                        : ''
                    }
                    {
                        discussion?.comments?.length > 0 &&
                        discussion.comments.map((comment) => (

                            <div className='details-boxes' key={comment.id} id={comment.id} >
                                <div className='project-sr'>
                                    <div className='project-peo-wrap'>
                                        <div className='sr-cstm-div-img-date cstm-date-cls'>
                                            <div className='project-peo-img sr-cstm-cls'>
                                                <img src={comment?.user?.profilePictureUrl} alt='client' />
                                                <div className='project-name-wrap'>
                                                    <h4>{comment?.user?.firstName} {comment?.user?.lastName}</h4>
                                                    <span>{comment?.user?.jobTitle}</span>
                                                    <span className='sr-cldr'>
                                                    <i className="fas fa-calendar-check"></i>
                                                        <p>
                                                            {new Date(comment?.createdAt).toLocaleString('en-US', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                year: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                // second: '2-digit',
                                                                hour12: true, // Ensures AM/PM format
                                                            })}
                                                        </p>
                                                        </span>
                                                </div>
                                            </div>
                                            <div className='project-peo-cont'>

                                                <div className='sr-react-date'>
                                                    {comment?.reactions && comment.reactions.length > 0 && (
                                                        <div className="sr-react" onClick={() => handleShowModal(comment.id)}>
                                                            {comment.reactions.some(reaction => reaction.like) && (
                                                                <div className="dis-btn">
                                                                    <>
                                                                        <div className='rect-icon'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47"><path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M10.782,7.539V4.945A1.945,1.945,0,0,0,8.836,3L6.242,8.836V15.97h7.315a1.3,1.3,0,0,0,1.3-1.1l.895-5.836a1.3,1.3,0,0,0-1.3-1.492ZM6.242,15.97H4.3a1.3,1.3,0,0,1-1.3-1.3V10.133a1.3,1.3,0,0,1,1.3-1.3H6.242" transform="translate(-2.25 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            )}
                                                            {comment.reactions.some(reaction => reaction.dislike) && (
                                                                <div className="dis-btn">
                                                                    <>
                                                                        <div className='rect-icon'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47"><path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M7.982,11.43v2.594A1.945,1.945,0,0,0,9.927,15.97l2.594-5.836V3H5.207a1.3,1.3,0,0,0-1.3,1.1L3.015,9.939a1.3,1.3,0,0,0,1.3,1.492ZM12.521,3h1.945a1.3,1.3,0,0,1,1.3,1.3V8.836a1.3,1.3,0,0,1-1.3,1.3H12.521" transform="translate(-2.247 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            )}
                                                            {comment.reactions.some(reaction => reaction.love) && (
                                                                <div className="dis-btn">
                                                                    <>
                                                                        <div className='rect-icon'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.788" height="14.574" viewBox="0 0 15.788 14.574"><path id="Icon_ionic-md-heart-empty" data-name="Icon ionic-md-heart-empty" d="M14.821,4.5a4.67,4.67,0,0,0-3.552,1.666A4.67,4.67,0,0,0,7.717,4.5,4.292,4.292,0,0,0,3.375,8.868c0,3.017,2.683,5.439,6.748,9.173l1.146,1.032,1.146-1.032c4.065-3.734,6.748-6.156,6.748-9.173A4.292,4.292,0,0,0,14.821,4.5ZM11.755,17l-.159.148-.326.3-.326-.3L10.783,17a48.239,48.239,0,0,1-4.657-4.63,5.568,5.568,0,0,1-1.533-3.5,3.157,3.157,0,0,1,.9-2.251,3.089,3.089,0,0,1,2.224-.9,3.481,3.481,0,0,1,2.622,1.233l.93,1.1.93-1.1a3.468,3.468,0,0,1,2.622-1.233,3.1,3.1,0,0,1,2.228.9,3.163,3.163,0,0,1,.9,2.251,5.6,5.6,0,0,1-1.533,3.5A48.337,48.337,0,0,1,11.755,17Z" transform="translate(-3.375 -4.5)" fill="#fff"></path></svg>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            )}
                                                            {comment.reactions.some(reaction => reaction.approved) && (
                                                                <div className="dis-btn">
                                                                    <>
                                                                        <div className='rect-icon'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.518" height="13.67" viewBox="0 0 18.518 13.67"><path id="Icon_feather-check" data-name="Icon feather-check" d="M22.4,9,11.124,20.86,6,15.469" transform="translate(-4.94 -7.94)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            )}

                                                            {comment.reactions.some(reaction => reaction.not_approved) && (
                                                                <div className="dis-btn">
                                                                    <>
                                                                        <div className='rect-icon'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143" viewBox="0 0 14.143 14.143"><g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(7.072 -14.85) rotate(45)"><path id="Path_8" data-name="Path 8" d="M18,7.5v16" transform="translate(-2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><path id="Path_9" data-name="Path 9" d="M7.5,18h16" transform="translate(0 -2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path></g></svg>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            )}

                                                            <p>
                                                                {comment.reactions[0]?.Reacteduser?.firstName || 'Unknown'}
                                                                {comment.reactions.length > 1 && ` & ${comment.reactions.length - 1} Others`}
                                                            </p>
                                                        </div>
                                                    )}

                                                    <div className='edits-calender'>
                                                       



                                                    </div>
                                                    {/* <p dangerouslySetInnerHTML={{ __html: comment.comment }} /> */}
                                                </div>
                                            </div>
                                            <div className='react-post-out'>
                                            <div className='react-post' >
                                                <div className='react-inner' onClick={() => toggleApproveLists(comment.id)}>
                                                    <p>React to this post</p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.003" height="15.003" viewBox="0 0 15.003 15.003">
                                                        <g id="Icon_feather-smile" data-name="Icon feather-smile" transform="translate(-2.25 -2.25)">
                                                            <path id="Path_1" data-name="Path 1" d="M16.5,9.751A6.751,6.751,0,1,1,9.751,3,6.751,6.751,0,0,1,16.5,9.751Z" transform="translate(0 0)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                            <path id="Path_2" data-name="Path 2" d="M12,21a3.645,3.645,0,0,0,2.7,1.35A3.645,3.645,0,0,0,17.4,21" transform="translate(-4.949 -9.898)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                            <path id="Path_3" data-name="Path 3" d="M13.5,13.5h.007" transform="translate(-5.774 -5.774)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                            <path id="Path_4" data-name="Path 4" d="M22.5,13.5h.007" transform="translate(-10.723 -5.774)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                        </g>
                                                    </svg>
                                                </div>
                                                {
    // If the due date is not set, show "Set Due Date"
    !comment.dueDate ? (
      
           <p className="cstm-p-tag" onClick={() => handleShowUnModal(comment.id)}> Set Deadline </p>
    ) : (
        // Show the due date or the "Mark as Completed" button
        comment.completed ? (
            <span className="completed-text">Completed</span> // Display "Completed" if the task is marked as completed
        ) : (
            // For users who didn't set the due date and are not admins
            comment?.dueDateUser !== userData?.user.id && userData?.user.role !== '1' ? (
                <div className="due-date-display">
                    Due Date: {new Date(comment.dueDate).toLocaleDateString()}
                </div>
            ) : (
                <button
                className="global-icon-btn blue-btn set-date"
                onClick={() => handlemarkModal(comment.id)}
            >
                <div>
                    <span>Mark it as Completed</span><br></br>
                    {comment?.dueDate && !comment?.completed && ( // Only show the date if dueDate exists and the task is not completed
                        <span className="due-date-text">
                            {new Date(comment.dueDate).toLocaleString('en-US', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                year: 'numeric',
                                                            })}
                         
                        </span>
                    )}
                </div>
            </button>
            
            )
        )
    )
}

                                                {visibleApproveLists[comment.id] && (
                                                    <div className='approve-lists'>
                                                        <ul>
                                                            <li onClick={() => handleReactionClick('like', comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
                                                                <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M10.782,7.539V4.945A1.945,1.945,0,0,0,8.836,3L6.242,8.836V15.97h7.315a1.3,1.3,0,0,0,1.3-1.1l.895-5.836a1.3,1.3,0,0,0-1.3-1.492ZM6.242,15.97H4.3a1.3,1.3,0,0,1-1.3-1.3V10.133a1.3,1.3,0,0,1,1.3-1.3H6.242" transform="translate(-2.25 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                            </svg>
                                                                <span>Like</span></a></li>
                                                            <li onClick={() => handleReactionClick('dislike', comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
                                                                <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M7.982,11.43v2.594A1.945,1.945,0,0,0,9.927,15.97l2.594-5.836V3H5.207a1.3,1.3,0,0,0-1.3,1.1L3.015,9.939a1.3,1.3,0,0,0,1.3,1.492ZM12.521,3h1.945a1.3,1.3,0,0,1,1.3,1.3V8.836a1.3,1.3,0,0,1-1.3,1.3H12.521" transform="translate(-2.247 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                            </svg>
                                                                <span>Dislike</span></a></li>
                                                            <li onClick={() => handleReactionClick('love', comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="15.788" height="14.574" viewBox="0 0 15.788 14.574">
                                                                <path id="Icon_ionic-md-heart-empty" data-name="Icon ionic-md-heart-empty" d="M14.821,4.5a4.67,4.67,0,0,0-3.552,1.666A4.67,4.67,0,0,0,7.717,4.5,4.292,4.292,0,0,0,3.375,8.868c0,3.017,2.683,5.439,6.748,9.173l1.146,1.032,1.146-1.032c4.065-3.734,6.748-6.156,6.748-9.173A4.292,4.292,0,0,0,14.821,4.5ZM11.755,17l-.159.148-.326.3-.326-.3L10.783,17a48.239,48.239,0,0,1-4.657-4.63,5.568,5.568,0,0,1-1.533-3.5,3.157,3.157,0,0,1,.9-2.251,3.089,3.089,0,0,1,2.224-.9,3.481,3.481,0,0,1,2.622,1.233l.93,1.1.93-1.1a3.468,3.468,0,0,1,2.622-1.233,3.1,3.1,0,0,1,2.228.9,3.163,3.163,0,0,1,.9,2.251,5.6,5.6,0,0,1-1.533,3.5A48.337,48.337,0,0,1,11.755,17Z" transform="translate(-3.375 -4.5)" fill="#fff" />
                                                            </svg>
                                                                <span>Love</span></a></li>
                                                            <li onClick={() => handleReactionClick('approved', comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="18.518" height="13.67" viewBox="0 0 18.518 13.67">
                                                                <path id="Icon_feather-check" data-name="Icon feather-check" d="M22.4,9,11.124,20.86,6,15.469" transform="translate(-4.94 -7.94)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                            </svg>
                                                                <span>Approved</span></a></li>
                                                            <li onClick={() => handleReactionClick('not_approved', comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143" viewBox="0 0 14.143 14.143">
                                                                <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(7.072 -14.85) rotate(45)">
                                                                    <path id="Path_8" data-name="Path 8" d="M18,7.5v16" transform="translate(-2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                                    <path id="Path_9" data-name="Path 9" d="M7.5,18h16" transform="translate(0 -2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                                </g>
                                                            </svg>
                                                                <span>Not Approved</span></a></li>
                                                        </ul>
                                                    </div>
                                                )}
                                                 <div className='edit-delete'>
                                                {
                                                    comment.user.id === ActiveuserId && new Date() - new Date(comment.createdAt) <= 15 * 60 * 1000 && (
                                                        <>
                                                            <button className='btn' name='comment_id' value={comment.id} fileURl={comment.storages} onClick={handleEdit}><i className="fas fa-pencil-alt" style={{ pointerEvents: 'none' }}></i></button>
                                                            &nbsp;<span>|</span>&nbsp;
                                                            <button className='btn' name='comment_id' value={comment.id} onClick={handleDelete}> <i className="far fa-trash-alt" style={{ pointerEvents: 'none' }}></i></button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                            </div>

                                           
                                        </div>
                                        
                                        </div>

                                        
                                        <div className='details-edits-cal'>
                                            {comment.parentComment?.comment &&
                                            <p className='selected-comment' dangerouslySetInnerHTML={{ __html: comment.parentComment?.comment }} ></p>
                                            }
                                            <p dangerouslySetInnerHTML={{ __html: comment.comment }} ></p>
                                        </div>
                                        <DocumentPopup comment={comment} handleShowPreviewModal={handleShowPreviewModal} />
<div className='srcstm_comment' onClick={() => handleCommentClick(comment.id)}>
                                                Reply to this comment
                                            </div>


                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    <h5> Leave A New Message</h5>
                    <form onSubmit={handleSubmit} encType="multipart/form-data" ref={bottomRef}>
                        <div className='discuss-edit-inner'>
                            <div style={{ position: 'relative' }}>
                            {selectedComment && (
                                <div className="selected-comment">
                                    <div className='slected-icons'>
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 10L3.29289 10.7071L2.58579 10L3.29289 9.29289L4 10ZM21 18C21 18.5523 20.5523 19 20 19C19.4477 19 19 18.5523 19 18L21 18ZM8.29289 15.7071L3.29289 10.7071L4.70711 9.29289L9.70711 14.2929L8.29289 15.7071ZM3.29289 9.29289L8.29289 4.29289L9.70711 5.70711L4.70711 10.7071L3.29289 9.29289ZM4 9L14 9L14 11L4 11L4 9ZM21 16L21 18L19 18L19 16L21 16ZM14 9C17.866 9 21 12.134 21 16L19 16C19 13.2386 16.7614 11 14 11L14 9Z" fill="#33363F"></path> </g></svg>
                                    <span onClick={cleartheCommentID}><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" fill="#000000"></path> </g></svg></span>
                                    </div>
                                    <p className="selected-comment-text">Replying to below message.</p>
                                <q> {selectedComment.comment.replace(/<\/?[^>]+(>|$)/g, "")} </q>
                                    </div>
                            )}
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formData.comment}
                                    onChange={handleEditorChange}
                                    ref={editorRef}
                                />

                                {showTooltip && mentionSearch && filteredUsers.length > 0 && (
                                    <div
                                        ref={tooltipRef}
                                        style={{
                                            position: 'absolute',
                                            top: '84px',  // Adjust to fit your layout
                                            left: '10px', // Adjust to fit your layout
                                            backgroundColor: 'white',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                            zIndex: 9,
                                            width: '200px',
                                            padding: '5px',
                                            maxHeight: '150px',
                                            overflowY: 'auto',  // Allow scrolling if many results
                                        }}
                                    >
                                        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                                            {filteredUsers.map(user => (
                                                <li
                                                    key={user.id}
                                                    style={{
                                                        padding: '5px',
                                                        cursor: 'pointer',
                                                        borderBottom: '1px solid #eee',
                                                    }}
                                                    onClick={() => insertMention(user)}
                                                >
                                                    {user.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <p style={{ color: 'red' }}>{errors?.comment}</p>
                            <div className="upload-btn-wrapper mt-3">
                                <button className="attached-btn">
                                    <i className="fas fa-paperclip"></i> Attach Files
                                </button>
                                <input
                                    type="file"
                                    name="files"
                                    id="fileupload"
                                    className="file-upload-input"
                                    onChange={handleChange}
                                    multiple
                                />
                            </div>
                            <div className="file-preview">
                                {filesDataDisplay.length > 0 &&
                                    filesDataDisplay.map((file) => (

                                        <div key={file.name}>{getFileDisplay(file)}</div>
                                    ))}
                                {displayFile ? (
                                    <div className="filesContainer">
                                        {
                                            (() => {
                                                const fileURL = displayFile;

                                                // Extract the base URL before the query parameters
                                                const baseURL = fileURL.split('?')[0];
                                                const fileExtension = baseURL.split('.').pop().toLowerCase();

                                                if (['jpg', 'jpeg', 'png', 'svg'].includes(fileExtension)) {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                                                            <img src={fileURL} alt="file" />
                                                        </a>
                                                    );
                                                } else if (fileExtension === 'csv') {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                                                            <img src="/assets/images/csv-svgrepo-com.svg" alt="csv file" />
                                                        </a>
                                                    );
                                                } else if (fileExtension === 'doc' || fileExtension === 'docx') {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                                                            <img src="/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg" alt="doc file" />
                                                        </a>
                                                    );
                                                } else if (fileExtension === 'html') {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                                                            <img src="/assets/images/html-5-svgrepo-com.svg" alt="html file" />
                                                        </a>
                                                    );
                                                } else if (fileExtension === 'xml') {
                                                    return (
                                                        <img src="/assets/images/xml-file-format-variant-svgrepo-com.svg" alt="xml file" />
                                                    );
                                                } else if (fileExtension === 'zip') {
                                                    return (
                                                        <img src='/assets/images/zip.png' alt='' style={{ width: '50px', height: '50px' }} />
                                                    );
                                                } else if (fileExtension === 'pdf') {
                                                    return (
                                                        <img src='/assets/images/pdf.png' alt='' style={{ width: '50px', height: '50px' }} />
                                                    );
                                                } else if (fileExtension === 'mp4') {
                                                    return (
                                                        <img src='/assets/images/file-unknown.svg' alt='file' />

                                                    );
                                                } else {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>

                                                            <img src="/assets/images/file-unknown.svg" alt="unknown file" key={fileURL} />
                                                        </a>
                                                    );
                                                }
                                            })()
                                        }
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {errorsFile && (
                            <div className="alert alert-danger" role="alert">{errorsFile}</div>
                        )

                        }
                        <button className="global-icon-btn orange-btn" onClick={handleSubmit}>
                            {editable ? 'Update Message' : 'Add Message'}</button>
                    </form>
                    <DiscusionReactions showModal={showModal} setShowModal={setShowModal} id={id} discussion={discussion} commentId={selectedCommentId} />
                    <SetDueDates showUnModal={showUnModal} setShowUnModal={setShowUnModal} id={commentId} onClose={refreshState}/>

                    {/* <FilePreview
                        showPreviewModal={showPreviewModal}
                        setShowPreviewModal={setShowPreviewModal}
                        previewFile={previewFile}
                        /> */}

                </div>
                <div className='detail-grid right'>
                    <div className="det-table">
                        <div className='pass-card-out'>
                            <h2>Team Members</h2>
                            <div className="password-card check">
                                <div className="psw-tems-btns">
                                    <div className="psw-team-btn"><button>Team</button></div>
                                    <div className="disc-edit-txt"><p>Check to enable email alerts.</p></div>
                                </div>
                                <div className="pass-check-in dt">
                                    {
                                        Object.keys(teamMembers).length > 0 ?
                                            teamMembers.users.length > 0 ?
                                                <div className="row">
                                                    {
                                                        teamMembers.users.map((team) =>
                                                            <div className="pass-check" key={team.id}>
                                                                <input type="checkbox" name='userId' value={team.id} onChange={handleClick} checked={usersOutFromDisscusion.includes(team.id) ? false : true} />
                                                                {/* <Link to={`/teamMember-edit/${team.id}`}> */}
                                                                    <div className="project-peo-img">
                                                                        <LazyImageWithSpinner src={team.profilePictureUrl} alt={`${team.firstName} ${team.lastName}`} />
                                                                    </div>
                                                                {/* </Link> */}
                                                                <Link to={`/teamMember-edit/${team.id}`}>
                                                                    <div className="project-peo-cont">
                                                                        <p>{team?.firstName + ' ' + team?.lastName}</p><br></br>
                                                                        <p>{team?.jobTitle}</p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                : 'No team member found'
                                            : 'No team member found'
                                    }
                                    <div className="add-mem">
                                        <a href="javascript:void(0)">
                                            {
                                                showAddTeamMember ?
                                                    <i className="fa fa-window-close" onClick={closeAddMembersPopup}></i>
                                                    :
                                                    <i className="fas fa-plus" onClick={AddMembersPopup}><span> Invite More Team Members</span></i>
                                            }
                                        </a>
                                        {
                                            showAddTeamMember ?
                                                <MultiSelect
                                                    options={options}
                                                    value={selected}
                                                    onChange={setSelected}
                                                    labelledBy="sadsa"
                                                />
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="password-card check">
                                <div className="psw-tems-btns select-psw-btns">
                                    <div className="psw-team-btn"><button>Status</button></div>
                                    <div className='status-dropdown-psw'>
                                        <div className="status-dropdown selectDropdown">
                                            <select id="statusSelect" onChange={handleStatusChange} value={status || ""}>
                                                <option value="">Select Status</option>
                                                <option value="On Hold">On Hold</option>
                                                <option value="Work In Progress">Work In Progress</option>
                                                <option value="QA Review">QA Review</option>
                                                <option value="PM Review">PM Review</option>
                                                <option value="Management Review">Management Review</option>
                                                <option value="Client Review">Client Review</option>
                                                <option value="Pending Go-Live">Pending Go-Live</option>
                                                <option value="Complete">Complete</option>
                                            </select>
                                            <div class="dropIcon"><svg xmlns="
                                    http://www.w3.org/2000/svg"
                                                width="13.971" height="7.988" viewBox="0 0 13.971 7.988"><path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path></svg></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal centered show={show} onHide={handleClose} className='add-team-modal'>
                <Modal.Header closeButton>
                    <h4 className="modal-title">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="19.2"
                            viewBox="0 0 24 19.2"
                        >
                            <path
                                id="Icon_awesome-user-plus"
                                data-name="Icon awesome-user-plus"
                                d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
                            />
                        </svg>
                        Delete Discussion
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="calender-box">
                        <div className="question-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="86.875" height="86.875" viewBox="0 0 86.875 86.875">
                                <path id="Icon_awesome-question-circle" data-name="Icon awesome-question-circle" d="M87.438,44A43.438,43.438,0,1,1,44,.563,43.435,43.435,0,0,1,87.438,44ZM45.166,14.925c-9.545,0-15.633,4.021-20.414,11.167a2.1,2.1,0,0,0,.476,2.848l6.078,4.608a2.1,2.1,0,0,0,2.919-.372c3.129-3.969,5.274-6.27,10.037-6.27,3.578,0,8,2.3,8,5.773,0,2.623-2.165,3.97-5.7,5.951-4.12,2.31-9.572,5.184-9.572,12.376v.7a2.1,2.1,0,0,0,2.1,2.1H48.9a2.1,2.1,0,0,0,2.1-2.1v-.233c0-4.985,14.57-5.193,14.57-18.683C65.576,22.631,55.038,14.925,45.166,14.925ZM44,58.362a8.057,8.057,0,1,0,8.057,8.057A8.066,8.066,0,0,0,44,58.362Z" transform="translate(-0.563 -0.563)" />
                            </svg>
                        </div>
                        <p>Are you sure you want to Delete Discussion ?</p>
                    </div>
                    <div className="form-btn-rows">
                        <button type="button " onClick={handleConfirmDelete} className="btn global-icon-btn orange-btn ">Yes</button>
                        <button type="button" onClick={handleClose} className="btn global-icon-btn ">No</button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    )
}

export default DiscussionDetail